@import "../../asset/scss/mixin.scss";


.banner_section {
  padding-top: 84px;
  padding-bottom: 120px;
  .banner_top_section {
    max-width: 85%;
    margin: 0 auto !important;
    align-items: center;
    .left_side_section {
      .top {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .title {
          font-size: 2.5rem;
          font-weight: 600;
          line-height: 3.75rem;
          color: #000;
        }
        .text {
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 2.25rem;
          color: #000;
          span{
            text-decoration: underline;
          }
        }
      }
      .buttons {
        padding-top: 16px;
        display: flex;
        align-items: center;
        gap: 1rem;
        .know-more-button{
          font-size: 1rem;
          font-weight: 500;
          line-height: 2rem;
          color: #276229;
          text-decoration: underline;
          cursor: pointer;
          &:hover{
            color: #3B9243;
          }
        }
      }
      .counter-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        .border {
          height: 67px;
          width: 1px;
          background-color: #000000;
        }

        .number {
          font-size: 2rem;
          font-weight: 600;
          line-height: 1.5rem;
        }

        .number_title {
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 1.5rem;
        }
      }
    }
    .right_side_section {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .counter-container-mobile{
    display: none;
  }
  .banner_bottom_section {
    height: 54px;
    position: relative;
    overflow: hidden;
    .photobanner {
      position: absolute;
      bottom: 0%;
      left: 0px;
      overflow: hidden;
      white-space: nowrap;
      animation: bannermove 15s linear infinite;
      .text {
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.75rem;
        color: #176939;
      }
    }
  }
}

// @include breakpoint(desktop){
//   .banner_section {
//     padding-top: 5rem;
//     .banner_top_section {
//       max-width: 90%;
//       .left_side_section {
//        .top{
//         display: flex;
//         flex-direction: column;
//         gap: 0.5rem;
//         .title {
// font-size: 24px;
// line-height: 38px;
// letter-spacing: -0.02em;
//         }
//         .text {
//           font-size: 16px;
//           line-height: 24px;
//         }
//        }
//         .bottom {
//           .border {
//             height: 67px;
//             width: 1px;
//             background-color: #000000;
//           }
//           .number {
//             font-size: 26.12px;
//             line-height: 19.59px;
//           }
//           .number_title {
//             font-size: 19.59px;
//             line-height: 19.59px;
//           }
//         }
//       }
//       .right_side_section {
//         img {
//           width: 100%;
//         }
//       }
//     }
//   }
// }

@include breakpoint(mobile){
  .banner_section {
    padding-top: 20px;
    padding-bottom: 60px;
    .banner_top_section {
      max-width: 90%;
      // text-align: center;
//       .left_side_section {
//         .top{
// // gap: 1rem;
// .buttons{
//   justify-content: center;
//   gap: 1rem;
// }  
//         }
//         .counter-container-desktop{
//           display: none !important;
//         } 
//       }
  .left_side_section {
      .top {
        align-items: center;
        gap: 5px;
        .title {
          font-size: 1.5rem;
          line-height: 2.375rem;
          // text-align: center;
        }
        .text {
          font-size: 1rem;
          line-height: 1.5rem;
          // text-align: center;
        }
      }
      .buttons {
        // display: flex;
        // gap: 1rem;
        align-self: start;
      }
     .counter-container-desktop{
      display: none;
     }
    }
    }
    .counter-container-mobile{
      display: block;
      max-width: 90%;
      margin: 0 auto;
.counter-section{
  padding-top: 16px;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
align-items: center;
row-gap: 10px;
}
.bottom {
 div{
  &:nth-child(1), &:nth-child(4){
    justify-self: flex-end;
  }
 }
  .border {
    height: 54px;
    width: 1px;
    background-color: #000000;
    justify-self: center;
  }
  .number {
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 1.25px;
  }
  .number_title {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
}


    }
    .banner_bottom_section {
      height: 45.16px;
      .photobanner {
        .text {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.375rem;
        }
      }
    } 
  }
}

@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}


