@import "../../asset/scss/mixin.scss";

.why-choose-tmm-section{
    background-color: #F9EDDC;
    .why-choose-tmm-container{
        max-width: 85%;
        margin: 0 auto;
        padding: 120px 0;
.why-choose-tmm-heading{
font-size: 4.5rem;
font-weight: 300;
line-height: 5.5rem;
text-align: center;
color: #2D3748;
span{
    color: #276229;
}
}
.content{
    padding: 64px 0;
    .content-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        .img{}
        .title{
font-size: 1.5rem;
font-weight: 600;
line-height: 2.25rem;
color: #2D3748;
text-align: center;
            span{
                color: #276229;
            }
        }
        .text{
font-size: 1.5rem;
font-weight: 300;
line-height: 1.75rem;
text-align: center;
color: #2D3748;
        }
    }
}
    }


    .continue-section{
        position: relative;
        bottom: 20px;
        height: 42.09px;
        transform: skewY(-1.5deg); /* Proper centering with transform */
        background: #003F15;
        overflow: hidden;
        .continue-container{
            position: absolute;
          bottom: 0%;
          left: 0px;
          overflow: hidden;
          white-space: nowrap;
          animation: bannermove 15s linear infinite;
            font-size: 2rem;
            font-weight: 300;
            line-height: 3.25rem;
            letter-spacing: 0.01em;
            color: #F8C325;
    
        }
    }
}

@include breakpoint(mobile){
    .why-choose-tmm-section{
        background-color: #F9EDDC;
    
        .why-choose-tmm-container{
            padding: 60px 0;
            .why-choose-tmm-heading{
    font-size: 2.25rem;
    line-height: 2.75rem;
    }

    .content{
        padding: 32px 0;
        .content-item{
            gap: 0.5rem;
            .img{
               width: 32px;
            }
            .title{
    font-size: 0.75rem;
    line-height: 1.125rem;
            }
            .text{
    font-size: 0.75rem;
    line-height: 0.875rem;
            }
        }
    }
        }
    
        .continue-section{
            bottom: 10px;
            height: 21px;
            transform: skewY(-1.5deg); /* Proper centering with transform */
            overflow: hidden;
            .continue-container{
                font-size: 1rem;
                line-height: 1.625rem;
                color: #F8C325;
        
            }
        }
    
    } 
}



@keyframes bannermove {
    // 0% {
    //   transform: translate(0, 0);
    // }
    // 100% {
    //   transform: translate(-50%, 0);
    // }
    0% {
        transform: translateX(0); /* Start at container's width */
      }
      100% {
        transform: translateX(-100%); /* Move completely off-screen */
      }
  }