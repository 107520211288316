

@import "../../asset/scss/mixin.scss";

.SatisfiedPartners-section {
  padding: 120px 0;
  .SatisfiedPartners-container {
    max-width: 85%;
    margin: 0 auto;
    .centered-container {
      width: fit-content;
      margin: 0 auto;
      color: #176939;
      .title-style {
        position: relative;
        display: inline-block;
        font-size: 3.5rem;
        font-weight: 275;
        line-height: 4.5rem;
        text-align: center;
        z-index: 1;
        .middleTextBold {
          font-weight: 600;
        }
        .backgroundStyle {
          width: 800px; /* 850px */
          height: 60px; /*  */
          position: absolute;
          bottom: 0%;
          left: 0%;
          transform: translate(-50%, -50%);
          background: #f8c325;
          z-index: -1;
          transform: skewY(-2deg);
        }
      }
    }

    .carousel-section {
      padding-top: 64px;
    }

    .left_side{ 
      text-align: center;
      img {
        width: 80%;
        height: auto;
      }
    } 

    .right_side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .details-section {
        .stars img {
          margin-right: 0.5rem;
        }

        .card_text {
          font-size: 1.22rem; /* 19.47px */
          font-weight: 400;
          line-height: 1.96rem; /* 31.44px */
        }

        .card_carousel_name {
          font-size: 1.22rem; /* 19.47px */
          font-weight: 600;
          line-height: 1.35rem; /* 21.63px */
          padding: 1rem 0;

          .card_job-title {
            font-weight: 300;
          }
        }
      }

      .button-section {
        display: flex;
        align-items: center;
        gap: 2rem;
        color: #ffc448;
        font-size: 1.875rem; /* 30px */
        img {
          cursor: pointer;
        }
      }
    }
  }
}

/* Tablet Breakpoint */
@include breakpoint(tablet) {
  .SatisfiedPartners-section {
    padding: 80px 0;
    .SatisfiedPartners-container {
      .centered-container {
        .title-style {
          font-size: 2rem;
          line-height: 2.5rem;
          .backgroundStyle {
            width: 400px;
            height: 40px;
          }
        }
      }

      .carousel-section {
      padding-top: 48px;
        .right_side .details-section {
          .card_text {
            font-size: 0.875rem; /* 14px */
            line-height: 1.31rem; /* 21px */
          }

          .card_carousel_name {
            font-size: 1rem; /* 16px */
          }
        }
      }
    }
  }
}

/* Mobile Breakpoint */
@include breakpoint(mobile) {
  .SatisfiedPartners-section {
padding: 60px 0;
    .SatisfiedPartners-container {
      .centered-container {
        .title-style {
          font-size: 1.5rem; 
          line-height: 2rem; 
          .backgroundStyle {
            width: 250px;
            height: 30px;
          }
        }
      }

      .carousel-section {
        padding-top: 32px;
        .left_side{
          img{
            width: 40%;
          }
        }
        .right_side {
          text-align: center;
          .button-section{
            justify-content: center;
          }
        }
      }
    }
  }
}

