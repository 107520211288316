.policy-section{   
    padding: 2rem 5rem;
      background-position: right top;
      background-size: contain;
      background-repeat: no-repeat;
        height: 100vh;
        .policy-container{
            display: flex;
            flex-direction: column;
            height: 100%;
            .terms_section{
                flex: 1;
                overflow: auto;
                scroll-behavior: smooth;
                &::-webkit-scrollbar{
                    display: none;
                }
                section{
                    margin: 1rem 0;
                }
                .heading{
                    font-size: 18px;
                    font-weight: 600;
                    // color: $primary;
                }
                .title{
                    font-size: 16px;
                    font-weight: 500;
                    // color: $primary;
                }
                .text{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #2D3748;
                    padding-top: 0.5rem;
    text-align: justify;
                }
            }
        }
      
          
    
        ol {
            list-style-type: number; /* Ensures circles are used */
            margin-left: 20px; /* Add left margin for indentation */
            padding-left: 0; /* Adjust padding if necessary */
            color: #2D3748;
            li {
                margin-bottom: 10px; /* Adds space between list items for readability */
              }
              ul {
                list-style-type: circle; /* Ensures circles are used */
                margin-left: 20px; /* Add left margin for indentation */
                padding-left: 0; /* Adjust padding if necessary */
                li {
                    margin-bottom: 0px; /* Adds space between list items for readability */
                    .text{
                        padding-top: 0 !important;
                    }
                  }
              }
          }
          
    
    }