@import "../../asset/scss/mixin.scss";

.unit-economics-section{
    background-color: #EBF0EC;
    padding: 120px 0;
    .unit-economics-container{
        max-width: 85%;
        margin: 0 auto;
        .heading{
font-size: 4.5rem;
font-weight: 300;
line-height: 5.5rem;
color: #2D3748;
        }
        .top-section{
            padding-top: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            .left_side{
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top:1px solid #003F15; 
                border-bottom: 1px solid #003F15;
               .title{
font-size: 28px;
font-weight: 500;
line-height: 34.19px;
text-align: left;
color: #276229;
            }
            .text{
                font-size: 24px;
                font-weight: 700;
                line-height: 29.3px;
                text-align: left;
color: #2D3748;                 
            }
                }
                
            
            .right_side{
flex: 1;
display: flex;
flex-direction: column;
gap: 10px;
div{
display: flex;
align-items: center;
gap: 10px;
    .text{
        font-size: 18.74px;
        font-weight: 400;
        line-height: 21.55px;
        text-align: left;
        color: #000;
        span{
            font-weight: 700;
        }
}
}
            }
        }
        .middle-section{
            margin: 40px 0 20px;
            display: grid;
            grid-template-columns: 1fr 2rem 1.5fr;
            padding: 20px;
            background-color: #FFFFFF;
            border-radius: 10px;
            .unit-economics{
.title{
font-size: 28px;
font-weight: 700;
line-height: 34.19px;
text-align: left;
color: #276229;
width: fit-content;
border-top: 1px solid #003F15;
border-bottom: 1px solid #003F15;
}
.row{
   display: grid;
   grid-template-columns: 3fr 1rem 1fr 1rem 1fr;
   align-items: flex-start;
    border-bottom: 0.65px solid #003F15;
    padding: 10px 0;
    .left-side{
        grid-column: 1/2;
        display: flex;
        flex-direction: column;
        .label{
            font-family: Poppins;
font-size: 20.95px;
font-weight: 400;
line-height: 31.42px;
text-align: left;
color: #003F15;
        }
        .label-text{
            font-family: Poppins;
font-size: 12.26px;
font-weight: 400;
line-height: 14.1px;
text-align: left;
color: #000;
        }
    }
    .value{
        grid-column: 3/4;
        justify-self: end;
        font-family: Poppins;
font-size: 15.71px;
font-weight: 500;
line-height: 23.56px;
text-align: left;
color: #2D3748;
    }
    .percentage{
        grid-column: 5/6;
        justify-self: end;
        font-family: Poppins;
font-size: 15.71px;
font-weight: 500;
line-height: 23.56px;
text-align: left;
color: #2D3748;
    }
}
            }
            .chart-container{
                grid-column: 3/4;
                justify-self: end;
                align-self: center;
            }
        }
        .bottom-section{
            display: flex;
            align-items: center;
            justify-content: space-around;
           div{
            display: flex;
            align-items: center;
            gap: 20px;
            .text{
                cursor: pointer;
                font-family: Poppins;
font-size: 18.74px;
font-weight: 400;
line-height: 21.55px;
text-align: left;
color: #000;
text-decoration: underline;
            }
           }
        }
       }   
    }


    @include breakpoint(mobile){
        .unit-economics-section{
            padding: 60px 0;
            .unit-economics-container{
                .heading{
        font-size: 2.5rem;
        line-height: 3.5rem;
                }
                .top-section{
                    padding-top: 40px;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    gap: 20px;
                    .left_side{
                       .title{
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
                    }
                    .text{
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;                
                    }
                        }
                        
                    
                    .right_side{
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 5px;
        div{
        display: flex;
        align-items: center;
        gap: 10px;
            .text{
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
               
        }
        }
                    }
                }
                .middle-section{
                    margin: 40px 0 20px;
                    display: grid;
                    grid-template-columns: none;
                    grid-template-rows: auto 2rem auto;
                    padding: 20px;
                    background-color: #FFFFFF;
                    border-radius: 10px;
                    .unit-economics{
                        grid-column: none;
                        grid-row: 1/2;
        .title{
        font-size: 28px;
        font-weight: 700;
        line-height: 34.19px;
        text-align: left;
        color: #276229;
        width: fit-content;
        border-top: 1px solid #003F15;
        border-bottom: 1px solid #003F15;
        }
        .row{
           display: grid;
           grid-template-columns: 3fr 1rem 1fr 1rem 1fr;
           align-items: flex-start;
            border-bottom: 0.65px solid #003F15;
            padding: 10px 0;
            .left-side{
                grid-column: 1/2;
                display: flex;
                flex-direction: column;
                .label{
                    font-family: Poppins;
        font-size: 20.95px;
        font-weight: 400;
        line-height: 31.42px;
        text-align: left;
        color: #003F15;
                }
                .label-text{
                    font-family: Poppins;
        font-size: 12.26px;
        font-weight: 400;
        line-height: 14.1px;
        text-align: left;
        color: #000;
                }
            }
            .value{
                grid-column: 3/4;
                justify-self: end;
                font-family: Poppins;
        font-size: 15.71px;
        font-weight: 500;
        line-height: 23.56px;
        text-align: left;
        color: #2D3748;
            }
            .percentage{
                grid-column: 5/6;
                justify-self: end;
                font-family: Poppins;
        font-size: 15.71px;
        font-weight: 500;
        line-height: 23.56px;
        text-align: left;
        color: #2D3748;
            }
        }
                    }
                    .chart-container{
                        grid-column: none;
                        grid-row: 3/4;
                        justify-self: end;
                        align-self: center;
                        .recharts-responsive-container{
width: 100% !important;
height: auto !important;
                        }
                    }
                }
                .bottom-section{
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: space-around;
                    gap: 10px;
                   div{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    img{
                        width: 18px;
                    }
                    .text{
                        cursor: pointer;
                        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #000;
        text-decoration: underline;
                    }
                   }
                }
               }   
            }
    }
