@import "../../asset/scss/variable.scss";
@import "../../asset/scss/mixin.scss";

.about-section {
  margin-bottom: 12.5rem;
  .about-container {
    max-width: 85%;
    margin: 0 auto;
    .centered-container {
      width: fit-content;
      margin: 0 auto;
      color: $GreenG1;
      .title-style {
        position: relative;
        display: inline-block;
        font-size: 44px;
        font-weight: 275px;
        line-height: 66px;
        letter-spacing: -0.05em;
        text-align: center;
        z-index: 1;
        .middleTextBold {
          font-weight: 800;
        }
        .backgroundStyle {
          width: 399.31px;
          height: 55.53px;
          position: absolute;
          top: 5%;
          left: -8%;
          transform: translate(-50% -50%);
          background: #f8c325;
          z-index: -1;
          transform: skewY(-2deg);
        }
      }
    }
    .momo-logo {
      width: 276px;
      margin: 1.5rem auto;
      .logo {
        width: 100%;
      }
    }
    .about_text {
      font-size: 27.7px;
      font-weight: 300;
      line-height: 41.55px;
      color: $GreenG1;
    }

    .counter_section {
      .counter_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        .counter_content {
          .count {
            font-size: 83.47px;
            font-weight: 700;
            line-height: 125.2px;
            color: $GreenG1;
          }
          .count_text {
            font-size: 27.7px;
            font-weight: 300;
            line-height: 41.55px;
            color: $GreenG1;
          }
        }
      }
    }

    .card_section {
      display: flex;
      justify-content: space-evenly;
      .card {
        width: 279px;
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        border: 1.04px solid $Yellow;
        box-shadow: 0px 0px 10.41px 0px #0000000d;
        border-radius: 10.41px;
        .card_icon {
          font-size: 41.64px;
          font-weight: 500;
          line-height: 62.67px;
        }
        .card_text {
          font-size: 18.74px;
          font-weight: 500;
          line-height: 21.55px;
          color: $Black;
        }
      }
    }
  }
}

@include breakpoint (desktop) {
  .about-section {
    .about-container {
      
      .about_text {
        font-size: 26px;
      }
  
      .counter_section {
        .counter_container {
          .counter_content {
            .count {
              font-size: 80px;
              line-height: 120px;
            }
            .count_text {
              font-size: 26px;
              line-height: 40px;

            }
          }
        }
      }
    }
  }
}


@include breakpoint (sm-desktop) {
  .about-section {
    .about-container {
      
      .about_text {
        font-size: 22px;
      }
  
      .counter_section {
        .counter_container {
          .counter_content {
            .count {
              font-size: 70px;
              line-height: 110px;
            }
            .count_text {
              font-size: 22px;
              line-height: 35px;

            }
          }
        }
      }
    }
  }
}


@include breakpoint (tablet) {
  .about-section {
    margin-bottom: 8rem;
    .about-container {
      
      .about_text {
        font-size: 16px;
        line-height: 35px;
      }
  
      .counter_section {
        .counter_container {
          .counter_content {
            .count {
              font-size: 45px;
              line-height: 90px;
            }
            .count_text {
              font-size: 20px;
              line-height: 25px;

            }
          }
        }
      }
    }
  }
}


@include breakpoint(mobile) {
  .about-section {
    margin-bottom: 4rem;
    .about-container {
      .centered-container {
        .title-style {
          font-size: 14.26px;
          line-height: 21.4px;
          .backgroundStyle {
            width: 129.45px;
            height: 18px;
          }
        }
      }
      .momo-logo {
        width: 117.91px;
        margin: 1rem auto;
        .logo {
          width: 100%;
        }
      }
      .about_text {
        font-size: 10px;
        line-height: 15px;
        text-align: left;
      }

      .counter_section {
        .counter_container {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 3rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
          .counter_content {
            .count {
              font-size: 18.74px;
              line-height: 28.1px;
            }
            .count_text {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      }

      .card_section {
        .card {
          width: 113.72px;
          height: 26.9px;
          border: 0.42px solid $Yellow;
          box-shadow: 0px 0px 4.24px 0px #0000000d;
          border-radius: 4.24px;
          .card_icon {
            font-size: 16.97px;
            line-height: 25.54px;
          }
          .card_text {
            font-size: 7.64px;
            line-height: 8.78px;
            color: $Black;
          }
        }
      }
    }
  }
}
