@import "../../asset/scss/mixin.scss";



.have-section {
  background-color: #F8C3259C;
  .have-ques-section {
    max-width: 85%;
    margin: 0 auto;
    padding: 120px 0;
    .faq-title{
      font-size: 59.12px;
      font-weight: 600;
      line-height: 88.98px;
      text-align: center;
color: #30312C;
padding-bottom: 5.5rem;      
    }
    .have-ques-container {
    
      display: grid;
      grid-template-columns: 1fr 4rem 6fr;
      .left-side {
        color: #30312C;
        display: flex;
        flex-direction: column;
        gap: 1rem;       
        .heading {
          font-size: 55.01px;
          font-weight: 600;
          line-height: 57.56px;         
        }
          .text {
            font-family: Inter;
            font-size: 20.73px;
            font-weight: 400;
            line-height: 25.51px;
          }
      }

      .right-side {
        grid-column: 3/4;
        .ant-collapse {
          background-color: transparent;

          .ant-collapse-item {
            border-bottom: 1px solid #CEC492;

            .ant-collapse-header {
              padding: 1rem 0;
              display: flex;
              align-items: center;

              .col-title {
                font-size: 20px;
                font-weight: 500;
                line-height: 29px;
                letter-spacing: 0em;
                color: #251f17;
              }

              .ant-collapse-expand-icon {
                position: relative;
                width: 37.3px;
                height: 37.3px;
                background-color: #ffffff;
                border-radius: 18.66px;

                img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }

            .ant-collapse-content {
              .ant-collapse-content-box {
                padding-left: 0;

                .col-text {

                  font-size: 16px;
                  font-weight: 400;
                  line-height: 29px;
                  letter-spacing: 0em;
                  color: #544E47;

                }
              }

            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet){
  .have-section {
    .have-ques-section {
      max-width: 90%;
      padding: 48px 0;
      .faq-title{
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
          padding-bottom: 3.5rem;
        }
      .have-ques-container {
        .left-side {
          .heading {
font-size: 24px;
font-weight: 600;
line-height: 30px;    
          }
            .text {
font-size: 14px;
font-weight: 400;
line-height: 18px;
            }
        } 
        .right-side {
          grid-column: 3/4;
          .ant-collapse {
            .ant-collapse-item {
              .ant-collapse-header {
                .col-title {
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 18px;
                  color: #251f17;
                }
                .ant-collapse-expand-icon {
                  width: 15.33px;
                  height: 15.33px;
                  img {
                    width: 10px;
                  }
                }
              }
  
              .ant-collapse-content {
                .ant-collapse-content-box {
                  padding-right: 4rem;
  
                  .col-text {
font-size: 12px;
font-weight: 400;
line-height: 20px;
text-align: left;
                    color: #544E47;
  
                  }
                }
  
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {

  .have-section {
    .have-ques-section {
      padding: 32px 0;
      .faq-title{
        padding-bottom: 3rem;
      }
      .have-ques-container {
        display: grid;
        grid-template-columns: none;
        grid-template-rows: auto 4rem auto;
        .left-side {
          grid-column: none;
          grid-row: 3/4;
          align-items: center;
          text-align: center;
        }
  
        .right-side {
          grid-column: none;
          grid-row: 1/2;
        }
      }
    }
  }
}