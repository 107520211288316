@import "../../asset/scss/mixin.scss";

.franchise-overview-section {
  padding-top: 120px;
  .franchise-overview-container {
    max-width: 85%;
        margin: 0 auto;
    .franchise-title {
      width: fit-content;
      margin: 0 auto;
      margin-bottom: 10px;
      font-size: 3.5rem;
      font-weight: 275;
      line-height: 4.5rem;
      letter-spacing: -0.03em;      
      text-align: center;
      background-color: #F8C324;
      transform: skewY(-1deg);
      color: #176939;
      span {
        font-weight: 600;
      }
      div {
        transform: skewY(1deg);
      }
    }
    .centered-container {
      width: fit-content;
      color: #176939;
      margin: 0 auto;
      text-align: center;
      .title-style {
        position: relative;
        font-size: 3.5rem; /* Large font for desktop */
        font-weight: 275;
        line-height: 4.5rem;
        text-align: center;
        z-index: 1;   
        letter-spacing: -0.05em;
        .backgroundStyle {
          width: 500px;
          height: 60px;
          position: absolute;
          top: 0%;
          left: 5%;
          transform: translate(-50% -50%);
          background: #f8c325;
          z-index: -1;
          transform: skewY(-2deg);
        }
      }
    }
    .text-container{
padding-bottom: 64px;
.franchise_text {
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 3.75rem;
  text-align: center;
  color: #176939;
  padding: 32px 0;
  span {
    color: #251F17;
  }
}
.franchise-work_card {
  .work_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 298px;
    height: 46px;
    border: 1.04px solid #F8C324;
    border-radius: 10px;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.75rem;
    color: #000;
    background-color: #FFF;
    box-shadow: 0px 0px 10.41px 0px #0000000d;
    cursor: pointer;
    margin: 0 auto;
  }
}
    }
   
    .franchise_card-section {
      .card {
        display: flex;
        height: 380px;
        .content {
          align-self: flex-end;
          width: 85%;
          height: 78.32px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 1.5rem;
          background-color: #FFF;
          opacity: 0.9;
          .left-side {
            font-size: 1rem;
            font-weight: 300;
            line-height: 1.25rem;
            letter-spacing: -0.03em;
            color: #131210;
            span {
              font-weight: 600;
            }
          }
          .right-side {
            cursor: pointer;
            .arrow-btn {
              border: none;
              outline: none;
              width: 32px;
              height: 100%;
              // aspect-ratio: 1/1;
              border-radius: 4px;
              background-color: #176939;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.5s;
              img{
                width: 16px;
                aspect-ratio: 1/1;
              }
              &:hover {
                background-color: #3B9243;
              }
            }
          }
        }
      }
    }
  }
}
@include breakpoint (sm-desktop) {
  .franchise-overview-section {
    padding-top: 100px;
    .franchise-overview-container {
      .franchise-title {
        font-size: 2.5rem;
        line-height: 3.5rem;
      }
      .centered-container {
        .title-style {
          font-size: 2.5rem;
          line-height: 3.5rem;
          .backgroundStyle {
            width: 400px;
            height: 50px;
          }
        }
      }
      .text-container{
        .franchise_text{
          font-size: 2.75rem;
          line-height: 3rem;
         }
      }
      .franchise_card-section {
        .card {
          height: 300px;
          .content {
            width: 90%;
          }
        }
      }
    }
  }
}

@include breakpoint (tablet) {
  .franchise-overview-section {
    .franchise-overview-container {
      .franchise-title {
        font-size: 2rem;
          line-height: 2.5rem;
      }
      .centered-container {
        .title-style {
          font-size: 2rem;
          line-height: 2.5rem;
          .backgroundStyle {
            width: 250px;
            height: 40px;
          }
        }
      }
      .text-container{
padding-bottom: 48px;
.franchise_text{
  font-size: 2.5rem;
          line-height: 2.75rem;
 }
      }
      .franchise_card-section {
        .card {
          height: 250px;
          .content {
            width: 95%;
            padding: 0 1rem;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .franchise-overview-section {
    padding-top: 60px;
    .franchise-overview-container {
      .franchise-title {
        font-size: 1.5rem;
          line-height: 2rem;
      }
      .centered-container {
        .title-style {
          font-size: 1.5rem;
          line-height: 2rem;
          .backgroundStyle {
            width: 180px;
            height: 30px;
          }
        }
      }
      .text-container {
        // padding-top: 20px;
padding-bottom: 32px;
       .franchise_text{
        font-size: 1.5rem;
        line-height: 1.75rem;
        padding: 16px 0;
       }
    }
    .franchise_card-section {
      .card {
        height: 163.19px;
        .content {
          height: 33.61px;
          padding: 0rem 0.5rem;
          .left-side {
            font-size: 6.49px;
            line-height: 8.74px;
          }
          .right-side {
            cursor: pointer;
            .arrow-btn {
              // width: 10px;
              // width: 100%;
              height: 100%;
              border-radius: 1.81px;
              // img{
              //   // width: 20px;
              // aspect-ratio: 1/1;
              // }
            }
          }
        }
      }
    }
      }
          }
    }
  
