@import "../../asset/scss/variable.scss";
@import "../../asset/scss/mixin.scss";


.CapitalInvestment-section {
 padding: 120px 0;
  .CapitalInvestment-container {
    max-width: 85%;
    margin: 0 auto;
    .centered-container {
      width: fit-content;
      margin: 0 auto;
      color: $GreenG1;
      .title-style {
        position: relative;
        display: inline-block;
        font-size: 44px;
        font-weight: 275px;
        line-height: 66px;
        letter-spacing: -0.05em;
        text-align: center;
        z-index: 1;
        .middleTextBold {
          font-weight: 800;
        }
        .backgroundStyle {
          width: 401px;
          height: 55.81px;
          position: absolute;
          top: 5%;
          left: 0%;
          transform: translate(-50% -50%);
          background: #f8c325;
          z-index: -1;
          transform: skewY(-2deg);
        }
      }
    }
    .text {
      font-size: 27.7px;
      font-weight: 300;
      line-height: 41.55px;
      text-align: center;
      color: $GreenG1;
      padding: 2rem 0rem;
    }
    .table-container {
      table {
        // width: 75%;
        margin: 0 auto;
        thead {
          background-color: $GreenG1;
          td {
            padding: 1rem 2rem;
            font-size: 32px;
            font-weight: 500;
            line-height: 48px;
            color: $Yellow;
          }
        }
        tbody {
          tr {
            &:nth-child(odd) {
              background-color: #f8c32533;
            }
            &:nth-child(even) {
              background-color: #ccd9d066;
            }
            td {
              font-size: 28px;
              font-weight: 400;
              line-height: 42px;
              color: $GreenG1;
              padding: 1rem 2rem;
              &:nth-child(2) {
                font-size: 15.13px;
                font-weight: 300;
                line-height: 20.36px;
                color: #131210;
              }
            }
          }
        }
      }
      .package_cost {
        padding: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 32px;
        font-weight: 500;
        line-height: 48px;
        color: $Black;
        border-bottom: 1px solid;
        border-image-source: linear-gradient(
          90deg,
          #ffffff 0%,
          #176939 52%,
          #ffffff 100%
        );
        border-image-slice: 1;
        .right_side {
          font-weight: 700;
        }
      }
    }
    .optional-add-section {
      margin: 4rem 0;
      .optional-title {
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        color: $GreenG1;
      }
      .optional-text {
        font-size: 27.7px;
        font-weight: 300;
        line-height: 41.55px;
        color: $GreenG1;
        padding: 1rem 0;
      }
      .optional-card-container {
        display: grid;
        grid-template-columns: auto auto auto; 
        gap: 2rem;
        padding: 1rem 0;
        .optional-card {
          background-color: #ccd9d066;
          border: 1px solid $GreenG1;
          width: 100%;
          height: 789px;
          display: grid;
          grid-template-rows: auto auto;
          padding: 2rem;
          .top_side {
            justify-self: center;
            .card-img {
              width: 225px;
              aspect-ratio: 1/1;
              img {
                width: 100%;
              }
            }
          }
          .bottom{
            display: grid;
            grid-template-rows: auto auto;
            gap: 2rem;
            .middle_side {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              padding-top: 2rem;
              .card-title {
                font-size: 32px;
                font-weight: 600;
                line-height: 48px;
                text-align: center;
                color: $GreenG1;
              }
              .card-price {
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                text-align: center;
                color: $GreenG1;
                border-bottom: 1px solid;
                border-image-source: linear-gradient(
                  90deg,
                  #ffffff 0%,
                  #176939 52%,
                  #ffffff 100%
                );
                border-image-slice: 1;
              }
              .ques-content {
                .question {
                  font-size: 20px;
                  font-weight: 400;
                  line-height: 30px;
                  text-align: left;
                  color: $greenG1;
                }
                .answer {
                  font-size: 14px;
                  font-weight: 300;
                  line-height: 21px;
                  text-align: left;
                  color: $GreenG1;
                }
              }
            }
            .bottom_side {
              align-self: flex-end;
              .card_btn {
                border: 1.04px solid #f8c324;
                background-color: #ffffff;
                border-radius: 10.41px;
                height: 46px;
                width: 295px;
                display: flex;
                align-items: center;
                // justify-content: center;
                padding: 0 1rem;
                gap: 1rem;
                font-family: Poppins;
                font-size: 18.74px;
                font-weight: 500;
                line-height: 21.55px;
                text-align: left;
                color: $Black;
              }
            }
          }
          &:nth-child(2) {
            background-color: #fef3d366;
            border: 1px solid #f8c325;
          }
        }
      }
    }
    .button-section {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }
}

@include breakpoint (desktop) {
  .CapitalInvestment-section {
    .CapitalInvestment-container {
      .optional-add-section {
        .optional-card-container {
          gap: 1rem;
          padding: 1rem 0;
          .optional-card {
            padding: 1rem;
          }
        }
      }
    }
  }
}

@include breakpoint (sm-desktop) {
  .CapitalInvestment-section {
    margin-bottom: 12.5rem;
    .CapitalInvestment-container {
      .text {
        font-size: 24px;
      }
      .table-container {
        table {
          margin: 0 auto;
         
          tbody {
            tr {
              td {
                font-size: 24px;
                
              }
            }
          }
        }
      }
      .optional-add-section {
       
        .optional-card-container {
          grid-template-columns: auto auto; 
        
          .optional-card {
            &:nth-child(3) {
              grid-column: 1/3;
              height: 394px;
              display: flex;
              align-items: center;
              justify-content: space-around;
              gap: 2rem;
            }
          }
        }
      }
    }
  }
}

@include breakpoint (tablet) {
  .CapitalInvestment-section {
    margin-bottom: 8rem;
    .CapitalInvestment-container {
      .text {
        font-size: 20px;
      }
      .table-container {
        table {
          margin: 0 auto;
         
          tbody {
            tr {
              td {
                font-size: 14px;
                
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint (mobile){
  .CapitalInvestment-section {
    margin-bottom: 4rem;
    .CapitalInvestment-container {
      .centered-container {
        .title-style {
          font-size: 14.26px;
          line-height: 21.4px;
          .backgroundStyle {
            width: 146.66px;
            height: 18px;
          }
        }
      }
      .text {
        font-size:10px;
        line-height:15px;
        text-align: center;
        color: $GreenG1;
        padding: 1rem 1rem;
      }
      .table-container {
        table {
          thead {
            td {
              padding: 0.5rem 1rem;
              font-size: 10.69px;
              line-height: 16.03px;
            }
          }
          tbody {
            tr {
              td {
                font-size: 9.35px;
                line-height: 14.03px;
                padding: 0.5rem 1rem;
                &:nth-child(2) {
                  font-size: 6px;
                  line-height: 9px;
                }
              }
            }
          }
        }
        .package_cost {
          padding: 0.5rem 0;
          font-size: 10.69px;
          line-height: 16.03px;
          color: $Black;
          border-bottom: 0.33px solid;
          border-image-slice: 1;
        }
      }
      .optional-add-section {
        .optional-title {
          font-size: 8.47px;
          line-height: 12.7px;
        }
        .optional-text {
          font-size: 7.33px;
          line-height: 10.99px;
          padding: 0.5rem 0;
        }
        .optional-card-container {
          display: grid;
          grid-template-columns: auto auto; 
          .optional-card {
            border: 0.4px solid $GreenG1;
            width: 100%;
            height: 317.87px;
            padding: 0.4rem;
            .top_side {
              justify-self: center;
              .card-img {
                width: 90.65px;
              }
            }
            .bottom{
              display: grid;
              grid-template-rows: auto auto;
              gap: 0.5rem;
              .middle_side {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                padding-top: 1rem;
                .card-title {
                  font-size: 12.89px;
                  line-height:19.34px;
                }
                .card-price {
                  font-size: 7.25px;
                  line-height: 10.88px;
                  border-bottom: 0.4px solid;
                  border-image-source: linear-gradient(
                    90deg,
                    #ffffff 0%,
                    #176939 52%,
                    #ffffff 100%
                  );
                  border-image-slice: 1;
                }
               
                .ques-content {
                  .question {
                    font-size: 8.06px;
                    line-height: 12.09px;
                  }
                  .answer {
                    font-size: 5.64px;
                    line-height: 8.46px;
                  }
                }
              }
              .bottom_side {
                align-self: end;
                .card_btn {
                  border: 0.42px solid #f8c324;
                  background-color: #ffffff;
                  border-radius: 4.19px;
                  height: 18.53px;
                  width: 118.85px;
                  display: flex;
                  align-items: center;
                  // justify-content: center;
                  padding: 0 0.5rem;
                  gap: 0.5rem;
                  font-family: Poppins;
                  font-size: 7.55px;
                  font-weight: 500;
                  line-height: 8.68px;
                  text-align: left;
                  color: $Black;
                }
              }
            }
            &:nth-child(2) {
              background-color: #fef3d366;
              border: 1px solid #f8c325;
            }
            &:nth-child(3){
              grid-column: 1/3;
              // width: 100%;
              height: 146px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 4rem;
              .top_side {
              }
              .bottom{
                display: grid;
                grid-template-rows: auto auto;
                gap: 0.5rem;
                .middle_side {
                  display: flex;
                  flex-direction: column;
                  gap: 0.5rem;
                  padding-top: 1rem;
                  .card-title {
                    font-size: 12.89px;
                    line-height:19.34px;
                  }
                  .card-price {
                    font-size: 7.25px;
                    line-height: 10.88px;
                    border-bottom: 0.4px solid;
                    border-image-source: linear-gradient(
                      90deg,
                      #ffffff 0%,
                      #176939 52%,
                      #ffffff 100%
                    );
                    border-image-slice: 1;
                  }
                 
                  .ques-content {
                    .question {
                      font-size: 8.06px;
                      line-height: 12.09px;
                    }
                    .answer {
                      font-size: 5.64px;
                      line-height: 8.46px;
                    }
                  }
                }
                .bottom_side {
                  align-self: end;
                  .card_btn {
                    border: 0.42px solid #f8c324;
                    background-color: #ffffff;
                    border-radius: 4.19px;
                    height: 18.53px;
                    width: 118.85px;
                    display: flex;
                    align-items: center;
                    // justify-content: center;
                    padding: 0 0.5rem;
                    gap: 0.5rem;
                    font-family: Poppins;
                    font-size: 7.55px;
                    font-weight: 500;
                    line-height: 8.68px;
                    text-align: left;
                    color: $Black;
                  }
                }
              }
            }
          }
        }
      }



      .button-section {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
      }
    }
  }
  }

