@import "../../asset/scss/variable.scss";
@import "../../asset/scss/mixin.scss";


.outlets-section {
  margin-bottom: 12.5rem;
  .outlets-container {
    .centered-container {
      width: fit-content;
      margin: 0 auto;
      color: $GreenG1;
      .title-style {
        position: relative;
        display: inline-block;
        font-size: 44px;
        font-weight: 275px;
        line-height: 66px;
        letter-spacing: -0.05em;
        text-align: center;
        z-index: 1;
        .middleTextBold {
          font-weight: 800;
        }
        .backgroundStyle {
          width: 401.3px;
          height: 55.53px;
          position: absolute;
          top: 5%;
          left: -30%;
          transform: translate(-50% -50%);
          background: #f8c325;
          z-index: -1;
          transform: skewY(-2deg);
        }
      }
    }
    .outlets_text {
      font-size: 27.7px;
      font-weight: 300;
      line-height: 41.55px;
      text-align: center;
      color: $GreenG1;
      padding: 1.5rem 0;
    }
    .bottom-section{
      display: flex;
      flex-direction: column;
      .outlets_card-section {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .card {
          padding: 0 1rem;
          height: 46px;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          border: 1.04px solid $Yellow;
          border-radius: 10.41px;
          box-shadow: 0px 0px 10.41px 0px #0000000D;
          .card_icon {
            font-size: 24px;
            font-weight: 500;
            line-height: 36.12px;
          }
          .card_text {
            font-size: 18.74px;
            font-weight: 500;
            line-height: 21.55px;
            color: $Black;
          }
        }
      }
      .outlets-map{
          padding: 2.5rem 0;
          img{
              width: 100%;
              object-fit: contain;
          }
      }
    }
    
  }
}


@include breakpoint(mobile){
  .outlets-section {
    margin-bottom: 4rem;
    .outlets-container {
      .centered-container {
        .title-style {
          font-size: 14.26px;
          line-height: 21.4px;
          .backgroundStyle {
            width: 129.45px;
            height: 18px;
          }
        }
      }
      .outlets_text {
        font-size: 10px;
        line-height: 15px;
        padding: 1rem 0;
      }
.bottom-section{
  flex-direction: column-reverse;
  .outlets_card-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    .card {
      padding: 0 1rem;
      height: 25.55px;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      border: 0.58px solid $Yellow;
      border-radius: 5.78px;
      box-shadow: 0px 0px 10.41px 0px #0000000D;
      .card_icon {
        font-size: 13.33px;
        line-height: 20.06px;
      }
      .card_text {
        font-size: 10.41px;
        line-height: 11.97px;
      }
    }
  }
  .outlets-map{
      padding: 1.5rem 0;
      img{
          width: 100%;
          object-fit: contain;
      }
  }
}
      
    }
  }
  
}