@import "../../asset/scss/variable.scss";
@import "../../asset/scss/mixin.scss";

.LocationSelection-section {
  margin-bottom: 12.5rem;
  .LocationSelection-container {
    .centered-container {
      width: fit-content;
      margin: 0 auto;
      color: $GreenG1;
      .title-style {
        position: relative;
        display: inline-block;
        font-size: 56.73px;
        font-weight: 275px;
        line-height: 85.09px;
        letter-spacing: -0.05em;
        text-align: center;
        z-index: 1;
        .middleTextBold {
          font-weight: 600;
        }
        .backgroundStyle {
          width: 503.89px;
          height: 70.08px;
          position: absolute;
          top: 5%;
          left: 8%;
          transform: translate(-50% -50%);
          background: #f8c325;
          z-index: -1;
          transform: skewY(-2deg);
        }
      }
    }
    .recommendation-text {
      width: fit-content;
      margin: 0 auto;
      padding: 1rem 2rem;
      font-family: Poppins;
      font-size: 38.91px;
      font-weight: 400;
      line-height: 38.91px;
      letter-spacing: -0.03em;
      text-align: center;
      color: $white;
      background-color: $GreenG1;
      transform: skewY(-3deg);
      position: relative;
      left: 5%;
    }
    .search-bar {
      width: 75%;
      margin: 0 auto;
      padding: 2rem 0;
      img {
        width: 100%;
      }
    }
    .location-selection-text {
      font-size: 24.01px;
      font-weight: 300;
      line-height: 36.01px;
      letter-spacing: 0.01em;
      text-align: center;
      color: $Black;
    }
    .search-card {
      width: 60%;
      margin: 2rem auto;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f8c32560;
      padding: 2rem;
      .left_side {
        .title {
          font-size: 18.86px;
          font-weight: 500;
          line-height: 28.29px;
          letter-spacing: 0.01em;
          text-align: left;
        }
        li {
          font-size: 18.86px;
          font-weight: 300;
          line-height: 28.29px;
          letter-spacing: 0.01em;
          text-align: left;
        }
      }
      .right_side {
        width: 130.31px;
        img {
          width: 100%;
        }
      }
    }
    .play-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      .play_btn {
        width: 46px;
        aspect-ratio: 1/1;
        box-shadow: 0px 8.67px 18.78px 0px #17693942;
        background-color: $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .play_btn-text {
        font-family: Poppins;
        font-size: 17.33px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.01em;
        text-align: left;
        color: $GreenG1;
      }
    }

    .recommendation-card_content {
      padding: 2rem;
      .recommendation-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 425.76px;
        background-size: cover;
        border-radius: 24.45px;
        padding: 1rem;
        .num_text {
          font-size: 40.35px;
          font-weight: 700;
          line-height: 77.05px;
          letter-spacing: -0.03em;
          text-align: left;
          color: $Yellow;
        }
        .insider_card {
          background-color: $yellowY4;
          opacity: 0.9;
          padding: 0.5rem;
          border-radius: 6px;

          .item_title {
            font-family: Poppins;
            font-size: 24.45px;
            font-weight: 700;
            line-height: 36.67px;
            letter-spacing: -0.03em;
            text-align: left;

            color: $GreenG1;
          }
          .item_text {
            font-family: Poppins;
            font-size: 20.37px;
            font-weight: 400;
            line-height: 28.52px;
            letter-spacing: -0.03em;
            text-align: left;

            color: $GreenG1;
          }
        }
      }
      .recommendation-card_1 {
        background: url("../../asset/images/additional_img_1.webp") center
          no-repeat;
      }
      .recommendation-card_2 {
        background: url("../../asset/images/additional_img_2.webp") center
          no-repeat;
      }
      .recommendation-card_3 {
        background: url("../../asset/images/additional_img_3.webp") center
          no-repeat;
      }
      .recommendation-card_4 {
        background: url("../../asset/images/additional_img_4.webp") center
          no-repeat;
      }
    }

    .recommendation-cards {
      display: flex;
      flex-wrap: wrap; 
      align-items: center;
      justify-content: space-between;
      .card {
        width: 219.39px;

        background-color: $yellowY4;
        opacity: 0.9;
        padding: 0.5rem;
        border-radius: 6px;

        .item_title {
          font-family: Poppins;
          font-size: 16.15px;
          font-weight: 700;
          line-height: 24.23px;
          letter-spacing: -0.03em;
          text-align: left;

          color: $GreenG1;
        }
        .item_text {
          font-family: Poppins;
          font-size: 13.46px;
          font-weight: 400;
          line-height: 18.84px;
          letter-spacing: -0.03em;
          text-align: left;

          color: $GreenG1;
        }
      }
    }
  }
}

@include breakpoint(mobile) {

    .LocationSelection-section {
        margin-bottom: 4rem;
        .LocationSelection-container {
            .centered-container {
                .title-style {
                  font-size: 15.12px;
                  line-height: 22.69px;
                  .backgroundStyle {
                    width: 134.34px;
                    height: 18.68px;
                  }
                }
              }
          .recommendation-text {
            width: fit-content;
            margin: 0 auto;
            padding: 0.2rem 1rem;
            font-size: 10.37px;
            line-height: 10.37px;
          }
          .search-bar {
            padding: 1rem 0;
           
          }
          .location-selection-text {
font-size: 10px;
line-height: 15px;
letter-spacing: 0.01em;

            color: $Black;
          }
          .search-card {
            width: 75%;
            margin: 1rem auto;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #f8c32560;
            padding: 1rem;
            .left_side {
              .title {
                font-size: 6.46px;
                font-weight: 500;
                line-height: 9.7px;
                letter-spacing: 0.01em;
                
              }
              li {
                font-size: 8px;
                font-weight: 300;
                line-height: 12px;
                letter-spacing: 0.01em;
              }
            }
            .right_side {
              width: 44.66px;
              img {
                width: 100%;
              }
            }
          }
          .play-btn-container {
            gap: 1rem;
            .play_btn {
              width: 23px;
              img{
                width: 9.2px;
              }
            }
            .play_btn-text {
              font-size: 8.63px;
              line-height: 12.94px;
            }
          }
      
          .recommendation-card_content {
            padding: 1rem;
            .recommendation-card {
              height: 201.53px;
              border-radius: 11.57px;
              padding: 1rem;
              .num_text {
font-size: 19.1px;
font-weight: 700;
line-height: 36.47px;
letter-spacing: -0.03em;

                color: $Yellow;
              }
              .insider_card {
                background-color: $yellowY4;
                opacity: 0.9;
                padding: 0.5rem;
                border-radius: 3px;
      
                .item_title {
                    font-family: Poppins;
                    font-size: 11.57px;
                    font-weight: 700;
                    line-height: 17.36px;
                    letter-spacing: -0.03em;
                    text-align: left;
                    
      
                  color: $GreenG1;
                }
                .item_text {
                    font-family: Poppins;
                    font-size: 9.64px;
                    font-weight: 400;
                    line-height: 13.5px;
                    letter-spacing: -0.03em;
                    text-align: left;
                    
      
                  color: $GreenG1;
                }
              }
            }
            .recommendation-card_1 {
              background: url("../../asset/images/additional_img_1.webp") center
                no-repeat;
            }
            .recommendation-card_2 {
              background: url("../../asset/images/additional_img_2.webp") center
                no-repeat;
            }
            .recommendation-card_3 {
              background: url("../../asset/images/additional_img_3.webp") center
                no-repeat;
            }
            .recommendation-card_4 {
              background: url("../../asset/images/additional_img_4.webp") center
                no-repeat;
            }
          }
      
          .recommendation-cards {
            padding: 0 2rem;
            row-gap: 1rem;
            .card {
              width: 103.84px;
      
              background-color: $yellowY4;
              opacity: 0.9;
              padding: 0.3rem;
              border-radius: 3px;
      
              .item_title {
                font-family: Poppins;
                font-size: 7.64px;
                font-weight: 700;
                line-height: 11.47px;
                letter-spacing: -0.03em;
                text-align: left;
                
      
                color: $GreenG1;
              }
              .item_text {
                font-family: Poppins;
font-size: 6.37px;
font-weight: 400;
line-height: 8.92px;
letter-spacing: -0.03em;
text-align: left;

                color: $GreenG1;
              }
            }
          }
        }
      }
}
