@import "../../asset/scss/variable.scss";
@import "../../asset/scss/mixin.scss";

.socialmedia-section{
  padding-top: 64px;
    // background-color: #EBF0EC;
    .socialmedia-container{
      max-width: 85%;
      margin: 0 auto;
      .centered-container {
        width: fit-content;
        color: #176939;
        margin: 0 auto;
        text-align: center;
        .title-style {
          position: relative;
          display: inline-block;
          font-size: 3.5rem; /* Large font for desktop */
          font-weight: 275;
          line-height: 4.5rem;
          text-align: center;
          z-index: 1;      
          .middleTextBold {
            font-weight: 600;
          }
          .backgroundStyle {
            width: 250px;
            height: 60px;
            position: absolute;
            top: 50%;
            right: -15%;
            // right: -35%;
            transform: translate(-50%, -50%) skewY(-2deg); /* Proper centering with transform */
            background: #f8c325;
            z-index: -1;
          }
        }
      }
    }
}

@include breakpoint(mobile) {
  .socialmedia-section{
    padding-top: 32px;
      .socialmedia-container{
        .centered-container {
          .title-style {
            position: relative;
            display: inline-block;
            font-size: 1.5rem;
            line-height: 3rem;
            .backgroundStyle {
              width: 100px;
              height: 30px;
              position: absolute;
              top: 50%;
              right: -15%;
              // right: -35%;
              transform: translate(-50%, -50%) skewY(-2deg); /* Proper centering with transform */
              background: #f8c325;
              z-index: -1;
            }
          }
        }
      }
  }
}