@import "../../asset/scss/mixin.scss";


.contact-section {
    .contact-container {
        position: relative;
        .contact-top-section {
            display: flex;
            align-items: center;
            background: linear-gradient(4.16deg, rgba(58, 56, 56, 0.63) 6.93%, rgba(0, 0, 0, 0.63) 95.4%),
                        url("../../asset/images/contact_bg.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: calc(100vh - 7rem); /* Correct calc usage */
            span {
                max-width: 85%;
                margin: 6rem auto 10rem;
                font-size: 5rem;
                font-weight: 700;
                line-height: 6rem;
                text-align: center;
                color: #FEF9E9;
            }
        }
        .contact-content {
            position: relative;
            top: -10rem;
            max-width: 85%;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            border-radius: 40px;
            border: 1px solid #276229;

            .contact-item {
                background-color: #F8C325;
                border-radius: 40px 0 0 40px;
                padding: 4rem;
                .title {
                    font-size: 3.25rem;
                    font-weight: 600;
                    line-height: 4.6rem;
                    letter-spacing: -0.02em;
                    color: #276229;
                }

                .contact-details {
                    display: flex;
                    flex-direction: column;
                    gap: 3rem;
                    border-bottom: 1px solid #276229;
                    padding: 3rem 0;
                    .contact-detail {
                        display: flex;
                        align-items: start;
                        gap: 1rem;
                        .icon {
                            background-color: #FFF;
                            width: 50px;
                            aspect-ratio: 1/1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50px;

                        }

                        .detail {
                            .heading {
                                font-size: 1.5rem;
                                font-weight: 600;
                                line-height: 2.25rem;
                                letter-spacing: -0.02em;
                                color: #276229;
                            }
                            .text {
                                font-size: 1.125rem;
                                font-weight: 400;
                                line-height: 1.75rem;
                                letter-spacing: -0.02em;
                                color: #276229;
                            }
                        }
                    }
                }

                .social-media-section {
                    padding-top: 3rem;
                    .heading {
                        font-size: 1.875rem;
                        font-weight: 600;
                        line-height: 2.875rem;
                        letter-spacing: -0.02em;
                        color: #276229;
                    }

                    .social-media-icons {
                        display: flex;
                        align-items: center;
                        gap: 3rem;
                    }
                }
            }

            .contact-form {
                background-color: #F9EDDC;
                border-radius: 0 40px 40px 0;
                padding: 4rem;

                .title {
                    font-size: 3.125rem;
                    font-weight: 600;
                    line-height: 4.6rem;
                    letter-spacing: -0.02em;
                    color: #276229;
                }
                .ant-form-item{
                    label{
font-size: 1.5rem;
font-weight: 400;
line-height: 2.25rem;
letter-spacing: -0.02em;
color: #276229;
                    }
                    .ant-input{
                        border-radius: 4px;
font-size: 1rem;
font-weight: 400;
line-height: 1.5rem;
letter-spacing: -0.02em;
border: none;

                    }
                    .ant-btn{
                        height: 60px;
                        background-color: #276229;
font-size: 1.5rem;
font-weight: 500;
line-height: 2.25rem;
letter-spacing: -0.02em;
color: #FFF;
                    }
                }
            }
        }

        .player-wrapper {
  max-width: 85%; /* Set a maximum width */
  margin: 0 auto;
  padding-bottom: 10rem;
        }
    }
}


@include breakpoint(mobile){
    .contact-section {
        .contact-container {
            .contact-top-section {
                height: calc(50vh - 40px); /* Correct calc usage */
                span {
                    max-width: 90%;
                    font-size: 2.5rem;
                    line-height: 3rem;
                }
            }
            .contact-content {
                top: -5rem;
                max-width: 90%;
                display: grid;
                grid-template-columns: none;
                grid-template-rows: 1fr 1fr;
                border-radius: 20px;
                border: 0.5px solid #276229;
                .contact-item {
                    border-radius: 0 0 20px 20px;
                    padding: 2rem;
                    .title {
                        font-size: 1.625rem;
                        line-height: 2.3rem;
                    }
                    .contact-details {
                        gap: 1.5rem;
                        border-bottom: 0.5px solid #276229;
                        padding: 1.5rem 0;
                        .contact-detail {
                            gap: 0.5rem;
                            .icon {
                                width: 25px;
    img{
        width: 50%;
    }
                            }
    
                            .detail {
                                .heading {
                                    font-size: 0.75rem;
                                    line-height: 1.125;
                                }
    
                                .text {
                                    font-size: 0.625rem;
                                    line-height: 1rem;
                                }
                            }
                        }
                    }
    
                    .social-media-section {
                        // padding-top: 1.5rem;
                        .heading {
                            font-size: 0.875rem;
                            line-height: 1.45rem;
                        }
                        .social-media-icons {
                            padding-top: 1.5rem;
                            gap: 1.5rem;
                            img{
                                width: 25px;
                            }
                        }
                    }
                }
    
                .contact-form {
                    grid-row: 1/2;
                    background-color: #F9EDDC;
                    border-radius: 20px 20px 0 0;
                    padding: 2rem;
                    .title {
                        font-size: 1.625rem;
                        line-height: 2.3rem;
                    }
                    .ant-form-item{
                        margin-bottom: 0;
                        .ant-form-item-label{
                            padding-bottom: 0px;
                            label{
                                font-size: 0.75rem;
                                line-height: 1.125rem;
                                                    }
                        }
                        .ant-input{
                            border-radius: 2px;
    font-size: 0.5rem;
    font-weight: 400;
    // line-height: 0.75rem;
                        }
                        .ant-btn{
                            margin-top: 8px;
                            height: 30px;
    font-size: 0.75rem;
    line-height: 1.125rem;
                        }
                    }
                }
            }
            .player-wrapper {
      max-width: 90%;
      padding-bottom: 5rem;
            }
        }
    }
}
  


