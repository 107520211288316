@import "../../asset/scss/mixin.scss";

.achievements-section{
    background-color: #003F15;
    .achievements-container{
        padding: 120px 0;
       .top-side{
        max-width: 85%;
        margin: 0 auto;
        .heading{
            font-size: 3.75rem;
            font-weight: 300;
            line-height: 4.5rem;
            color: #F9EDDC;
                        span{
                            font-size: 2.25rem;
                            font-weight: 700;
                            line-height: 2.75rem;
                            text-decoration: underline;
            
                        }
                    }
                    .sub-text{
                        padding-top: 32px;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2rem;
            text-align: justify;
            color: #F9EDDC;
                    }
                    .content{
                        padding: 64px 0;
                        .content-item{
                            .top_side{
                                position: relative;
            .title{
                font-size: 8.75rem;
            font-weight: 600;
            line-height: 13rem;
            color: #E5FFF0;
            }
            img{
                position: absolute;
            }
                            }
                            .text{
            font-size: 2rem;
            font-weight: 400;
            line-height: 3rem;
            color: #FAF6DB;
                            }
                        }
                    }
       }
        .city-box{
            max-width: 85%;
            margin: 0 auto;
            background-color: #F8C325;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 32px;
            .left_side{
font-size: 7.75rem;
font-weight: 700;
line-height: 9.5rem;
color: #276229;
                .text{
                    font-size: 2rem;
                    font-weight: 400;
                    line-height: 2.5rem;
                    color: #2D3748;
                }
            }
            .right_side{
                       display: flex;
                       flex-direction: column;
                       align-items: end;
                       gap: 32px;        
                span{
                    margin: 0 16px;
                    padding: 4px 16px;
font-size: 1.25rem;
font-weight: 400;
line-height: 1.5rem;
text-align: center;
color: #2D3748;
                    background-color: #F9EDDC;
                    border: 1px solid #176939;
                    box-shadow: 0px 0px 10.41px 0px #0000000D;
                    border-radius: 10px;

                }
            }
            .right_side-mobile{
                display: none;
            }
        }
    }
}

@include breakpoint(mobile){
    .achievements-section{
        .achievements-container{
            padding: 60px 0;
          .top-side{
            .heading{
                font-size: 2.5rem;
                line-height: 2.75rem;
                span{
                    font-size: 1.125rem;
                    line-height: 1.1625rem;
                }
            }
            .sub-text{
                padding-top: 16px;
  font-size: 1rem;
  line-height: 1.5rem;
            }
            .content{
                padding: 32px 0;
                .content-item{
                    .top_side{
                        position: relative;
    .title{
        font-size: 4rem;
    line-height: 5rem;
    }
    img{
        width: 18px;
    }
                    }
                    .text{
    font-size: 1rem;
    line-height: 1.5rem;
                    }
                }
            }
          }
            .city-box{
                max-width: 100%;
                border-radius: 0px;
                flex-direction: column;
                padding: 8px;
                text-align: center;
                gap: 16px;
                .left_side{
    font-size: 4.5rem;
    line-height: 5.5rem;
                    .text{
                        font-size: 1.5rem;
                        line-height: 1.5rem;
                    }
                }
                .right_side{
                    display: none;
                }
                .right_side-mobile{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 16px;        
             span{
                 padding: 4px 16px;
font-size: 1.25rem;
font-weight: 400;
line-height: 1.5rem;
                 background-color: #F9EDDC;
                 border: 0.5px solid #176939;
                 box-shadow: 0px 0px 10.41px 0px #0000000D;
                 border-radius: 5px;

             }
         }
            }
        }
    }
}