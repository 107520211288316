@import "../../asset/scss/mixin.scss";

.training-section {
  background-color: #F8C325;
  padding: 40px 0;
  .training-container {
    max-width: 75%;
    margin: 0 auto;
    .heading{
font-size: 4.5rem;
font-weight: 300;
line-height: 5.5rem;
text-align: center;
color: #2D3748;
    }
    .training-content {
      padding: 4rem 6rem 0;
      .card {
        // img {
        //   width: 100%;
        // }
        img{
          width: 100%;
          height: auto;
        }
        .item_text {
          font-family: Poppins;
          font-size: 2rem;
          font-weight: 400;
          line-height: 3rem;
          letter-spacing: -0.03em;
          text-align: center;          
color: #000;
        }
      }
    }
  }
}
.card-section{
  background-color: #F9EDDC;
  padding: 40px 0;
  .card-container{
    max-width: 85%;
    margin: 0 auto;
    .card{
      background-color: #F8C32447;
      border-radius: 10px;
      height: 90px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      gap: 20px;
      img{
        width: 50px;
        height: auto;
      }
      .text{
        font-size: 28px;
        font-weight: 500;
        line-height: 34.19px;
        text-align: left;
        color: #2D3748;
      }
    }
  }
}

@include breakpoint (desktop) {
  .training-section {
    .training-container { 
      .training-content {
        padding: 4rem 2rem 0;
      }
    }
  }
}

@include breakpoint (sm-desktop) {
  .training-section {
    .training-container {
      .training-content {
        padding: 4rem 1rem 0;
        .card {
          .item_text {
  font-size: 34px;
 
          }
        }
      }
    }
  }
}


@include breakpoint (tablet) {
  .training-section {
    margin-bottom: 8rem;
    .training-container {
      .training-content {
        padding: 4rem 0rem 0;
        .card {
          .item_text {
  font-size: 24px;
 line-height: 36px;
          }
        }
      }
    }
  }
}

@include breakpoint (mobile) {
  .training-section {
    margin-bottom: 4rem;
    .training-container {

      .heading{
        font-size: 2.5rem;
        font-weight: 300;
        line-height: 3.5rem;
            }
      .training-content {
        padding: 1rem 1rem;
        .card {
          img {
            width: 100%;
          }
          .item_text {
  font-size: 11.76px;
  line-height: 17.63px;
          }
        }
      }
    }
  }

  .card-section{
    background-color: #F9EDDC;
    padding: 20px 0;
    .card-container{
      max-width: 85%;
      margin: 0 auto;
      .card{
        background-color: #F8C32447;
        border-radius: 5px;
        height: 45px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        img{
          width: 25px;
          height: auto;
        }
        .text{
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
          color: #2D3748;
        }
      }
    }
  }
  
}
