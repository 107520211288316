@import "../../asset/scss/mixin.scss";


.cart-banner_section {
  padding-bottom: 60px;
  position: relative;
  padding-top: 50px;
  .background{
    position: absolute;
    top: 0px;
    background-color: #FFF7EC;
    width: 100%;
    height: 70vh;
  }
  .banner_top_section {
    max-width: 90%;
    margin: 0 auto !important;
    align-items: center;
    .left_side_section { 
      display: flex;
      flex-direction: column;
      gap: 1rem;    
        .title {
          font-size: 2.5rem;
          font-weight: 600;
          line-height: 3.75rem;
          color: #000;
        }
        .text {
          padding-top: 100px;
          // align-self: flex-end;
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 2.25rem;
          color: #000;
          text-align: end;
          span{
            text-decoration: underline;
          }
        }
      .counter-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        .border {
          height: 67px;
          width: 1px;
          background-color: #000000;
        }

        .number {
          font-size: 2rem;
          font-weight: 600;
          line-height: 1.5rem;
        }

        .number_title {
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 1.5rem;
        }
      }
    }
    .right_side_section {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .counter-container-mobile{
    display: none;
  }
  .banner_bottom_section {
    background-color: #FFF;
    height: 46px;
    position: relative;
    overflow: hidden;
    transform: skewY(-1.67deg);
    .photobanner {
      position: absolute;
      top: 0%;
      bottom: 0%;
      left: 0px;
      overflow: hidden;
      white-space: nowrap;
      animation: bannermove 15s linear infinite;
      .text {
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.75rem;
        color: #176939;
      }
    }
  }
}



@include breakpoint(mobile){
//   .banner_section {
//     padding-top: 20px;
//     padding-bottom: 60px;
//     .banner_top_section {
//       max-width: 90%;
//       // text-align: center;
// //       .left_side_section {
// //         .top{
// // // gap: 1rem;
// // .buttons{
// //   justify-content: center;
// //   gap: 1rem;
// // }  
// //         }
// //         .counter-container-desktop{
// //           display: none !important;
// //         } 
// //       }
//   .left_side_section {
//       .top {
//         align-items: center;
//         gap: 5px;
//         .title {
//           font-size: 1.5rem;
//           line-height: 2.375rem;
//           // text-align: center;
//         }
//         .text {
//           font-size: 1rem;
//           line-height: 1.5rem;
//           // text-align: center;
//         }
//       }
//       .buttons {
//         // display: flex;
//         // gap: 1rem;
//         align-self: start;
//       }
//      .counter-container-desktop{
//       display: none;
//      }
//     }
//     }
//     .counter-container-mobile{
//       display: block;
//       max-width: 90%;
//       margin: 0 auto;
// .counter-section{
//   padding-top: 16px;
// display: grid;
// grid-template-columns: 1fr 1fr 1fr;
// align-items: center;
// row-gap: 10px;
// }
// .bottom {
//  div{
//   &:nth-child(1), &:nth-child(4){
//     justify-self: flex-end;
//   }
//  }
//   .border {
//     height: 54px;
//     width: 1px;
//     background-color: #000000;
//     justify-self: center;
//   }
//   .number {
//     font-size: 1.625rem;
//     font-weight: 600;
//     line-height: 1.25px;
//   }
//   .number_title {
//     font-size: 1.25rem;
//     font-weight: 400;
//     line-height: 1.25rem;
//   }
// }


//     }
//     .banner_bottom_section {
//       height: 45.16px;
//       .photobanner {
//         .text {
//           font-size: 1rem;
//           font-weight: 400;
//           line-height: 1.375rem;
//         }
//       }
//     } 
//   }

.cart-banner_section {
  padding-bottom: 30px;
  padding-top: 25px;
  .background{
    height: 35vh;
  }
  .banner_top_section {
    max-width: 90%;
    margin: 0 auto !important;
    align-items: center;
    .left_side_section { 
      display: flex;
      flex-direction: column;
      gap: 1rem;    
        .title {
          padding-top: 30px;
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.75rem;
          color: #000;
        }
        .text {
          padding-top: 0px;
          // align-self: flex-end;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 0.75rem;
          color: #000;
          text-align: start;
          span{
            text-decoration: underline;
          }
        }
      .counter-section {
       
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        display: none;
        .border {
          height: 32px;
          width: 0.5px;
          background-color: #000000;
        }

        .number {
          font-size: 1rem;
          font-weight: 600;
          line-height: 0.75rem;
        }

        .number_title {
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 0.75rem;
        }
      }
    }
    .right_side_section {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .counter-container-mobile{
    display: block;
    max-width: 90%;
    margin: 0 auto;
.counter-section{
padding-top: 16px;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
align-items: center;
row-gap: 10px;
}
.bottom {
div{
&:nth-child(1), &:nth-child(4){
  justify-self: flex-end;
}
}
.border {
  height: 54px;
  width: 1px;
  background-color: #000000;
  justify-self: center;
}
.number {
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 1.25px;
}
.number_title {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25rem;
}
}


  }
  .banner_bottom_section {
    // height: 23px;
    height: 45.16px;
    .photobanner {
      .text {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.375rem;
      }
    }
  }
}
}

@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}


