@import "../../asset/scss/mixin.scss";


.software-driven-section{
    padding: 120px 0;
    background-color: #EBF0EC;
    .software-driven-container{
        max-width: 85%;
        margin: 0 auto;
        .heading{
font-size: 4.5rem;
font-weight: 300;
line-height: 5.5rem;
color: #2D3748;
        }
        .sub-heading{
font-size: 3rem;
font-weight: 700;
line-height: 3.5rem;
text-decoration: underline;
color: #003F15;
        }
        .cards{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 20px;
            padding-top: 40px;
            span{
                padding: 10px 20px;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.5rem;
        text-align: center;
        white-space: nowrap;
        color: #2D3748;
        border-radius: 10px;
                background: #F9EDDC;
                border: 1.04px solid #F8C324;
                box-shadow: 0px 0px 10.41px 0px #0000000D;
        
            }
        }
        .text{
font-size: 24px;
font-weight: 300;
line-height: 29.03px;
letter-spacing: 0.01em;
color: #2D3748;
padding: 40px 0;
        }
        .software-section{
            width: 100%;
            height: auto;
        }
    }
}

@include breakpoint(mobile) {
    .software-driven-section{
        padding: 60px 0;
        background-color: #EBF0EC;
        .software-driven-container{
            .heading{
    font-size: 2.5rem;
    line-height: 3.5rem;
            }
            .sub-heading{
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
            }
            .cards{
                gap: 10px;
                padding-top: 20px;
                span{
                    padding: 5px 10px;
            font-size: 1rem;
            line-height: 1rem;
            border-radius: 5px;
                }
            }
            .text{
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #2D3748;
    padding: 20px 0;
            }
            .software-section{
                width: 100%;
                height: auto;
            }
        }
    }
}