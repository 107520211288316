@import "../../asset/scss/mixin.scss";

.criteria-section{
    background-color: #F8C325;
    padding: 120px 0;
    .criteria-container{
        max-width: 90%;
        margin: 0 auto;
        .heading{
font-size: 4.5rem;
font-weight: 300;
line-height: 5.5rem;
color: #2D3748;
// white-space: nowrap;
        }
        .sub-heading{
font-size: 2.25rem;
font-weight: 400;
line-height: 3.25rem;
color: #2D3748;
text-decoration: underline;
        }
        .content{
            padding: 64px 0;
            .content-item{
                display: flex;
                gap: 10px;
                .left_side{
font-size: 6.5rem;
font-weight: 700;
line-height: 5rem;
color: #528E61;
                }
                .right_side{
                    .title{
font-size: 1.5rem;
font-weight: 700;
line-height: 1.75rem;
text-align: left;
color: #2D3748;
                    }
                    .text{
font-size: 1.25rem;
font-weight: 400;
line-height: 1.75rem;
                    }
                }
            }
        }
    }
}


@include breakpoint(mobile) {
    .criteria-section{
        padding: 60px 0;
        .criteria-container{
            .heading{
    font-size: 2.5rem;
    line-height: 2.75rem;
            }
            .sub-heading{
    font-size: 1.125rem;
    line-height: 1.1625rem;
            }
            .content{
                padding: 32px 0;
                .content-item{
                    display: flex;
                    gap: 5px;
                    .left_side{
    font-size: 3.25rem;
    line-height: 2.5rem;
                    }
                    .right_side{
                        .title{
    font-size: 0.75rem;
    line-height: 0.875rem;
    
                        }
                        .text{
    font-size: 0.625rem;
    line-height: 0.875rem;
                        }
                    }
                }
            }
        }
    }
}