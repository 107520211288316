@import "../../asset/scss/mixin.scss";


.chat-section {
  padding: 7rem 0;
  .chat-container {
    background: url("../../asset/images/chatBg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 25px;
    width: fit-content;
    margin: 0 auto;
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 4rem;
    .left-side{
      img{
        width: 343px;
      }
    }
    .right-side {
        padding: 2rem;
        border: 2px solid #176939;
        background-color: #F8C325;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
      .heading {
        font-size: 36px;
        font-weight: 500;
        line-height: 54px;
        letter-spacing: 0em;
        color: #000000;
      }
      .tik-container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .tik-content {
          display: flex;
          gap: 1rem;
          .text {
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0em;
            color: #000000;
          }
        }
      }
      .chatBtn{
        background-color: transparent;
        border: 1.5px solid #F9EDDC;
        width: 212px;
font-size: 16px;
font-weight: 700;
line-height: 24px;
color: #176939;
      }
    }
  }
}

@include breakpoint(sm-desktop){
  .chat-section {
    .chat-container {
      .left-side{
        img{
          width: 250px;
        }
      }
    }
  }
}

@include breakpoint(tablet){
  .chat-section {
    padding: 5rem 0;
    .chat-container {
      border-radius: 20px;
      padding: 4rem 2rem;
      .right-side {
          border-radius: 8px;
        .heading {
font-size: 14px;
font-weight: 600;
line-height: 18.9px;
        }
        .tik-container {
          .tik-content {
            .text {
              font-size: 12px;
              font-weight: 500;
              line-height: 12px;
            }
          }
        }
        .chatBtn{
          width: 200px;
  font-size: 14px;
        }
      }
    }
  }
}

@include breakpoint(mobile){
  .chat-section {
    padding: 4rem 0;
    .chat-container {
      border-radius: 0px;
      gap: 1rem;
      .left-side{
        img{
          width: 120px;
        }
      }
      .right-side {
          padding: 1rem;
          border: 0.7px solid #176939;
          border-radius: 3.5px;
          gap: 1rem;
        .heading {
font-size: 12.6px;
font-weight: 600;
line-height: 18.9px;
        }
        .tik-container {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          .tik-content {
            display: flex;
            gap: 1rem;
            img{
              width: 5%;
            }
            .text {
              font-size: 8px;
              font-weight: 500; 
              line-height: 12px;
            }
          }
        }
        .chatBtn{
          background-color: transparent;
          border: 1.5px solid #F9EDDC;
          width: 90px;
          font-size: 5.6px;
          font-weight: 700;
          line-height: 8.4px;
          text-align: center;     
  color: #176939;
        }
      }
    }
  }
}