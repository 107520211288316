@mixin breakpoint($point) {
   @if $point == desktop {
        @media (max-width: 1280px) {
            @content;
        }
    } 
    @else if $point == sm-desktop {
        @media (max-width: 1080px) {
            @content;
        }
    }
    @else if $point == tablet {
        @media (max-width: 998px) {
            @content;
        }
    }
    @else if $point == mobile {
        @media (max-width: 767px) {
            @content;
        }
    }
  }