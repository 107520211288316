//colors

$white: #FFFFFF;
$whiteW1: #F5F5F5;
$whiteW2: #ECEEF6;
$whiteW3: #EDEDED;
$whiteW4: #DEDEDE;

// green
$primary: #014529;
$greenG1: #176939;
$greenG2: #4d8a4f;
$greenG3: #F3F7F5;
$greenG4: #003F15;

// gray
$grayG1: #707070;
$grayG2: #3f3e3e;
$grayG3: #535353;
$grayG4: #D9D9D933;
$grayG5: #14141485;
$grayG6: #F4F6FB;
$grayG7: #797979;
$grayG8: #B0B0B0;
$grayG9: #484A58;

// yellow
$yellowY1: #f8c325;
$yellowY2: #FBC218;
$yellowY3: #FACB27;
$yellowY4: #F1EDE2;

// black
$black: #000000;
$blackB1: #0b090a;
$blackB2: #291e03;
$blackB3: #3d2d00;
$blackB4: #000000de;
$blackB5: #171717;
$blackB6: #232323;

$Black: #000000;
$BlackB1: #261E1E;


// blue

$blueB1: #001C3E;


//brown

$brownB1: #694F00;
$brownB2: #8F782F;



$white: #FFFFFF;
$OffWhite: #FFF7EC;
$Black: #000000;
$BlackB1: #261E1E;
$BlackB2: #131210;
$BlackB3: #251F17;
$Primary: #007050;
$GreenG1: #176939;
$Yellow: #F8C324;
$Brown: #694F00;