@import "../../asset/scss/mixin.scss";

.works-section {
  padding-top: 120px;
  .works-container {
    max-width: 85%;
    margin: 0 auto;
    .centered-container {
      width: fit-content;
      margin: 0 auto;
      color: #176939;
      text-align: center;
      .title-style {
        position: relative;
        display: inline-block;
        font-size: 3.5rem;
        font-weight: 275;
        line-height: 4.5rem;
        text-align: center;
        z-index: 1; 
        .secondaryTextBold {
          font-weight: 600;
        }
        .backgroundStyle {
          width: 200px;
          height: 60px;
          position: absolute;
          background: #f8c325;
          top: 50%;
          left: 75%;
          transform: translate(-50%, -50%) skewY(-2deg);
          z-index: -1;
        }
      }
    }

    .text {
      padding-top: 16px;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.69rem;
      text-align: center;
      color: #176939;
    }
    .content-section{
      padding-top: 64px;
      .content {
        .number-section {
          display: flex;
          align-items: center;
          gap: 2rem;
          .number {
            font-size: 10rem;
            font-weight: 800;
            color: #F8C3254F;
          }
        }
  
        .title {
          font-size: 1.5rem;
          font-weight: 600;
          color: #176939;
        }
  
        .middle {
          font-size: 1.14rem;
          font-weight: 400;
          color: #544E47;
          margin-bottom: 1rem;
        }
  
        .bottom {
          font-size: 1rem;
          font-weight: 600;
          color: #176939;
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }
}

/* Responsive adjustments */
@include breakpoint(sm-desktop) {
  .works-section {
    padding-top: 100px;
    .works-container {
      .centered-container {
        .title-style {
          font-size: 2.5rem;
          line-height: 3.5rem;
          .backgroundStyle {
            width: 150px;
            height: 50px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  // .works-section {
  //   .works-container {

  //   }
  // }
  .works-section {
    padding-top: 80px;
    .works-container {
      .centered-container {
        .title-style {
          font-size: 2rem;
          line-height: 2.5rem;
          .backgroundStyle {
            width: 120px;
            height: 40px;
          }
        }
      }
  
      .text {
        font-size: 1rem;
        line-height: 1.25rem;
      }
      .content-section{
        padding-top: 48px;
             .content {
        .number-section {
          .number {
            font-size: 5rem; /* 80px */
          }
        }

        .title {
          font-size: 1.125rem; /* 18px */
        }

        .middle {
          font-size: 1rem; /* 16px */
        }

        .bottom {
          font-size: 0.875rem; /* 14px */
        }
      }
      }
    }
  }
}

@include breakpoint(mobile) {
  .works-section {
    padding-top: 60px; /* 64px */

    .works-container {
      .centered-container {
        .title-style {
          font-size: 1.5rem; 
          line-height: 2rem; 
          .backgroundStyle {
            width: 100px;
            height: 30px;
          }
        }
      }
      .text {
        font-size: 1rem; /* 16px */
        // padding-bottom: 3rem; /* 48px */
      }
.content-section{
  padding-top: 32px;
  .content {
    text-align: center;
    .number-section {
      justify-content: center;
      img{
        width: 32px;
      }
      .number {
        font-size: 4rem; /* 64px */
      }
    }

    .title {
      font-size: 1rem;
    }

    .middle {
      font-size: 0.875rem;
    }

    .bottom {
      font-size: 0.875rem; /* 14px */
      justify-content: center;
    }
  }
}  
    }
  }
}
