@import "../../asset/scss/variable.scss";
@import "../../asset/scss/mixin.scss";


.RowMaterialDelivery-section {
  margin-bottom: 17.5rem;
  .RowMaterialDelivery-container {
    padding-top: 6rem;
    .centered-container {
      width: fit-content;
      margin: 0 auto;
      color: $GreenG1;
      .title-style {
        position: relative;
        display: inline-block;
        font-size: 44px;
        font-weight: 275px;
        line-height: 66px;
        letter-spacing: -0.05em;
        text-align: center;
        z-index: 1;
        .middleTextBold {
          font-weight: 800;
        }
        .backgroundStyle {
          width: 568.75px;
          height: 55.81px;
          position: absolute;
          top: 5%;
          left: 13%;
          transform: translate(-50% -50%);
          background: #f8c325;
          z-index: -1;
          transform: skewY(-2deg);
        }
      }
    }
    .text {
      font-size: 27.7px;
      font-weight: 300;
      line-height: 41.55px;
      text-align: center;
      color: $GreenG1;
      padding: 2rem 0;
    }
    .content-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding-top: 2rem;
      background-color: $GreenG1;
      width: 80%;
      margin: 0 auto;
      .text-content {
        display: flex;
        .food-delivery-title {
          padding-top: 1rem;
          font-size: 45.79px;
          font-weight: 300;
          line-height: 68.69px;
          letter-spacing: -0.03em;
          text-align: center;
          color: $Yellow;

          span {
            font-style: italic;
            font-weight: 700;
          }
        }
      }
      .food-delivery-img {
        img {
          width: 100%;
        }
      }
      .yellow-box {
        position: relative;
        top: 5rem;
        padding: 2rem 5rem;
        background-color: $Yellow;
        border-radius: 10px;
        font-size: 32.06px;
        line-height: 43.51px;

        font-weight: 400;
        letter-spacing: 0em;
        text-align: center;
        color: $Black;

        span {
          font-style: italic;
          font-weight: 700;
        }
      }
    }
  }
}

@include breakpoint (sm-desktop) {
  .RowMaterialDelivery-section {
    .RowMaterialDelivery-container {
      .content-section {
        width: 90%;
      }
    }
  }
}

@include breakpoint (tablet) {
  .RowMaterialDelivery-section {
    margin-bottom: 12.5rem;
    .RowMaterialDelivery-container {
      .content-section {
        width: 100%;
        .yellow-box {
          padding: 2rem 2rem;
        }
      }
    }
  }
}

@include breakpoint (mobile) {
  .RowMaterialDelivery-section {
    margin-bottom: 8rem;
    .RowMaterialDelivery-container {
      padding-top: 4rem;
      .centered-container {
        .title-style {
          font-size: 13.96px;
          line-height: 21.4px;
          .backgroundStyle {
            width: 180.47px;
            height: 17.71px;
          }
        }
      }
      .text {
        font-size: 10px;
        font-weight: 300;
        line-height: 15px;
        text-align: center;
        color: $GreenG1;
        padding: 1rem 0;
      }
      .content-section {
        padding-top: 1rem;
        .text-content {
          display: flex;
          .food-delivery-title {
            padding-top: 1rem;
            font-size: 13.54px;
            line-height: 20.31px;
          }
        }
        .yellow-box {
          position: relative;
          top: 2.2rem;
          padding: 1rem;
          background-color: $Yellow;
          border-radius: 5px;
          font-size: 9.48px;
          line-height: 12.86px;
        }
      }
    }
  }
}
