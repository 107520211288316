@import "../../asset/scss/mixin.scss";
.franchise-section{
  padding-top: 120px;
  .franchise-container{
      max-width: 85%;
      margin: 0 auto;
display: grid;
grid-template-rows: auto auto;
gap: 1rem;
      .top-container{
display: grid;
grid-template-columns: 2fr 1fr;
gap: 1rem;
.left_side{
  display: grid;
  grid-template-rows: auto auto;
  .horizental-card{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .top-heading-section {
    display: flex;
    position: relative;
    .coma {
      font-size: 150px;
      font-weight: 500;
      line-height: 165px;
      letter-spacing: 0em;
      text-align: left;
      color: #251f17;
      position: relative;
      top: -4rem;
    }
    .centered-container-first {
        width: fit-content;
        margin: 0 auto;
        color: #176939;
        .title-style {
          position: relative;
          display: inline-block;
          font-size: 3.5rem;
           font-weight: 275;
            line-height: 4.5rem;
          text-align: center;
          z-index: 1;
          .middleTextBold {
            font-weight: 600;
          }
          .backgroundStyle {
            width: 300px;
            height: 60px;
            position: absolute;
            top: 5%;
            right: 8%;
            transform: translate(-50% -50%);
            background: #f8c325;
            z-index: -1;
            transform: skewY(2deg);
          }
        }
      }
      .centered-container-second {
        width: fit-content;
        margin: 0 auto;
        color: #176939;
        .title-style {
          position: relative;
          display: inline-block;
          font-size: 3.5rem;
          font-weight: 275;
           line-height: 4.5rem;
          text-align: center;
          z-index: 1;
          .prefixTextBold {
            font-weight: 600;
          }
          .backgroundStyle {
            width: 400px;
            height: 60px;
            position: absolute;
            top: 5%;
            left: 8%;
            transform: translate(-50% -50%);
            background: #f8c325;
            z-index: -1;
            transform: skewY(-2deg);
          }
        }
      }
  }
}
      }
      .bottom-container{
        display: grid;
grid-template-columns: 1fr 1fr 1fr;
gap: 1rem;
      }
     

              .card{
              display: flex;
              align-items: flex-end;
              border-radius: 8px;
              .card-bg{
                width: 100%;
                  padding: 2rem;
                  background-image: url("../../asset/images/card-bg.png");
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  font-size: 2rem;
                  font-weight: 600;
                  line-height: 41.14px;
                  letter-spacing: 0.04em;
                  white-space: pre-line;
                  text-align: left;
                  color: #FFFFFF;  
                  border-radius: inherit;                  
              }
          }
          .text{
            padding-top: 8px;
font-size: 1.25rem;
font-weight: 300;
line-height: 1.875rem;
color: #000;
          }
                    .card-1{
                      height: 370px;
              background-image: url("../../asset/images/low-investment.png");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
          }
          .card-2{
              height: 370px;
              background-image: url("../../asset/images/inhouse\ momo.png");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
          }
                      .card-3{
                          height: 535px;
              background-image: url("../../asset/images/well-trained-staff.png");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
          }
          .card-4{
              height: 370px;
              background-image: url("../../asset/images/technologyDriven.png");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              background-color: #176939;
          }
          .card-5{
            height: 370px;
            background-image: url("../../asset/images/zero-westage-modal.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
                      .card-6{
                          height: 370px;
              background-image: url("../../asset/images/menu-items.png");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
          }
      }
}

@include breakpoint(tablet){
  .franchise-section{
    padding-top: 5rem;
    .franchise-container{
        max-width: 90%;
        .top-container{
  .left_side{
    .top-heading-section {
      .coma {
        font-size: 71.82px;
        line-height: 79px;
      }
      .centered-container-first {
          .title-style {
            font-size: 2rem;
            line-height: 2.5rem;
            .backgroundStyle {
              width: 123.06px;
              height: 32.33px;
            }
          }
        }
        .centered-container-second {
          .title-style {
            font-size: 2rem;
            line-height: 2.5rem;
            .backgroundStyle {
              width: 171.38px;
              height: 25.64px;
            }
          }
        }
    }
  }
        }
        .bottom-container{
          display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
        }
       
  
                .card{
                border-radius: 4px;
                .card-bg{    
font-size: 0.875rem;
line-height: 1rem;         
                }
            }
            .text{
              font-size: 0.75rem;
              line-height: 1.125rem;
            }
                      .card-1{
                        height: 157.62px;
            }
            .card-2{
                height: 157.62px;
            }
                        .card-3{
                            height: 250px;
                            background-image: url("../../asset/images/well-trained-staff-mobile.png");
            }
            .card-4{
                height:157.62px;
            }
            .card-5{
              height: 157.62px;
          }
                        .card-6{
                            height: 157.62px;
                            background-image: url("../../asset/images/menu-items-mobile.png");
            }
        }
  }
}

@include breakpoint(mobile){
  .franchise-section{
    padding-top: 60px;
    .franchise-container{
        .top-container{
          display: grid;
grid-template-columns: none;
grid-template-rows: auto auto;
gap: 1rem;
  .left_side{
    display: grid;
    grid-template-rows: auto auto;
    .horizental-card{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
    .top-heading-section {
      padding-top: 32px;
      justify-content: center;
      .coma {
        font-size: 71.82px;
        line-height: 79px;
      }
      .centered-container-first {
          .title-style {
            font-size: 1.5rem;
            line-height: 2rem;
            .backgroundStyle {
              width: 123.06px;
              height: 30px;
            }
          }
        }
        .centered-container-second {
          .title-style {
            font-size: 1.5rem;
          line-height: 2rem;
            .backgroundStyle {
              width: 171.38px;
              height: 30px;
            }
          }
        }
    }
  }
        }
        .bottom-container{
          display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  .bottom-container-last{
    grid-column: 1/3;
  }
        }
        .card{
          .card-bg{
            padding: 8px;                
        }
        }

                        .card-3{
                            height: 157.62px;
            }
        }
  }
}



