// @import "./asset/scss/variable.scss";
@import "./asset/scss/mixin.scss";
@import "./asset/scss/commanClass.scss";
@import "./asset/scss/animation.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  font-size: 16px; /* Default root font size for desktop */
}

@media (max-width: 768px) {
  html {
    font-size: 14px; /* Reduce root font size on smaller screens (1rem = 14px) */
  }
}

@media (max-width: 480px) {
  html {
    font-size: 12px; /* Further reduce for mobile (1rem = 12px) */
  }
}


a {
  text-decoration: none;
}

li {
  list-style: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7de3a8 !important;
  width: 1px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 6.11px;
  height: 87px;
  background: #276229;
  border-radius: 3.05321px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c0c0c0;
}

@media only screen and (max-width: 767px) {
  .non-mobile {
    display: none !important;
  }
}
@media (min-width: 767px) {
  .only-mobile {
    display: none !important;
  }
}
