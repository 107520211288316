@import "../../asset/scss/variable.scss";
@import "../../asset/scss/mixin.scss";

.growth-section {
  margin-bottom: 12.5rem;
  .growth-container {
    max-width: 85%;
    margin: 0 auto;
    .centered-container {
      width: fit-content;
      margin: 0 auto;
      color: $GreenG1;
      .title-style {
        position: relative;
        display: inline-block;
        font-size: 44px;
        font-weight: 275px;
        line-height: 66px;
        letter-spacing: -0.05em;
        text-align: center;
        z-index: 1;
        .middleTextBold {
          font-weight: 600;
        }
        .backgroundStyle {
          width: 401px;
          height: 55.81px;
          position: absolute;
          top: 5%;
          left: -20%;
          transform: translate(-50% -50%);
          background: #f8c325;
          z-index: -1;
          transform: skewY(-2deg);
        }
      }
    }
    .growth_text {
      font-size: 27.7px;
      font-weight: 300;
      line-height: 41.55px;
      text-align: center;
      color: $GreenG1;
      padding-top: 1.5rem;
    }
    .growth-graph {
      padding: 2rem 0;
      width: 100%;
      margin: 0 auto;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .growth_btn-section {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .left_side {
        font-size: 27.7px;
        font-weight: 300;
        line-height: 41.55px;
        color: $GreenG1;
      }
      .right_side {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
  }
}

@include breakpoint(sm-desktop) {
  .growth-section {
    margin-bottom: 12.5rem;
    .growth-container {
      .growth_btn-section {
        .left_side {
          font-size: 22px;
        }
        .right_side {
          gap: 0.5rem;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .growth-section {
    margin-bottom: 8rem;
    .growth-container {
      .growth_text {
        font-size: 20px;
        font-weight: 300;
        line-height: 34px;
        text-align: center;
        color: $GreenG1;
        padding-top: 1.5rem;
      }
      .growth_btn-section {
        .left_side {
          font-size: 16px;
          line-height: 24px;        
        }
        .right_side {
          gap: 0.5rem;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .growth-section {
    margin-bottom: 4rem;
    .growth-container {
      .centered-container {
        .title-style {
          font-size: 14.26px;
          line-height: 21.4px;
          .backgroundStyle {
            width: 129.45px;
            height: 18px;
          }
        }
      }
      .growth_text {
        font-size: 10px;
        line-height: 15px;
        padding-top: 1rem;
      }
      .growth-graph {
        padding: 1rem 0;
        width: 80%;
      }
      .growth_btn-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .left_side {
          font-size: 10px;
          line-height: 15px;
        }
        .right_side {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
      }
    }
  }
}
