@import "../../asset/scss/mixin.scss";

.footer-section{
    background-color: #276229;
    .footer-container{
        .footer-content{
            display: grid;
            grid-template-columns: 1fr 2rem 1fr 2rem 1fr 2rem 1fr;
            
            .detail{
                grid-column: 1/2;
font-size: 18px;
font-weight: 400;
line-height: 27px;
color: #F9EDDC;
            }
            .list-1{
                grid-column: 3/4;
            }
            .list-2{
                grid-column: 5/6;
            }
            .list-3{
                grid-column: 7/8;
            }
            .list{
display: grid;
grid-template-columns: auto;
gap: 0.5rem;
justify-content: space-around;
                .list_title{

font-size: 15.75px;
font-weight: 700;
line-height: 23.63px;
color: #FDEFC4;
white-space: nowrap;
                }
                .list_item{
font-size: 15.75px;
font-weight: 400;
line-height: 23.63px;
letter-spacing: 0.36000001430511475px;
color: #F9EDDC;
                }
                .social_icons{
                    gap: 20px;
                    
                    img{
                        cursor: pointer;
                        width: 24px;
                    }
                }
            }
        }
       
    }
    .ant-divider{
        background-color: #FFFFFF4F;
    }
    .rights-text{
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        text-align: center;
        color: #F9EDDC;
                }
}

@include breakpoint(tablet){
    .footer-section{
        background-color: #276229;
        .footer-container{
            .footer-content{
                display: grid;
                grid-template-columns: 1fr 2rem 1fr 2rem 1fr 2rem 1fr;
                .detail{
                    grid-column: 1/2;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 17.99px;
                }
                .list-1{
                    grid-column: 3/4;
                }
                .list-2{
                    grid-column: 5/6;
                }
                .list-3{
                    grid-column: 7/8;
                }
                .list{
    display: grid;
    grid-template-columns: auto;
    gap: 0.5rem;
    
justify-content: space-between;
                    .list_title{
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 23.56px;
    white-space: nowrap;
                    }
                    .list_item{
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17.95px; 
                    }
                    .social_icons{
                        img{
                            width: 10.29px;
                        }
                    }
                }
            }
           
        }
        .ant-divider{
            background-color: #FFFFFF4F;
        }
        .rights-text{
            font-size: 12px;
            font-weight: 300;
            line-height: 18px;
                    }
    }
}

@include breakpoint(mobile){
    .footer-section{
        .footer-container{
            .footer-content{
                display: grid;
                grid-template-columns: none;
                grid-template-rows: 1fr 2rem 1fr 2rem 1fr 2rem 1fr;
                .detail{
                    grid-column: none;
                    grid-row: 1/2;
                }
                .list-1{
                    grid-column: none;
grid-row: 3/4;
                }
                .list-2{
                    grid-column: none;
                    grid-row: 5/6;
                }
                .list-3{
                    grid-column: none;
                    grid-row: 7/8;
                }
                .list{
    display: grid;
    grid-template-columns: auto;
    gap: 0.5rem;
                    .list_title{
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 23.56px;
    white-space: nowrap;
                    }
                    .list_item{
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17.95px; 
                    }
                    .social_icons{
                        gap: 10px;
                        img{
                            width: 10.29px;
                        }
                    }
                }
            }
           
        }
        .ant-divider{
            background-color: #FFFFFF4F;
        }
        .rights-text{
            font-size: 12px;
            font-weight: 300;
            line-height: 18px;
                    }
    }
}