@import "../../asset/scss/mixin.scss";


.main-nav {
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: #F8C325;
  width: 100%;
  height: 80px;
  box-shadow: 0px -1px 1px 0px #0000001A inset;
  display: grid;
  grid-template-columns: 4rem 1fr 4fr 1fr 4rem;

  .logo {
    display: grid;
    grid-column: 2/3;
    justify-content: start;
    align-items: center;

    img {
      width: 100%;
    }
  }

  .menu-link {
    grid-column: 3/4;

    ul {
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      li {
        position: relative;

       .menu-item{
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.20000000298023224px;
          color: #2D3748;
          transition: 0.3s ease-in-out;
          cursor: pointer;
          &:hover {
            color: #176939;
          }

          &.active {
            font-weight: 600;
            color: #176939;

            &::after {
              content: "";
              width: 50%;
              height: 2px;
              background-color: #176939;
              position: absolute;
              bottom: -30px;
              left: 50%;
              /* Push the element from the left edge by 50% */
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }

  .last-column {
    grid-column: 4/5;
    display: grid;
    align-items: center;
    justify-content: end;

    .mobile-menu-button-wrapper {
      display: none;
    }
  }
  .mobile-menu {
    display: none;
    height: 100vh;
    width: 100vw;
    background-color: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1;

    &.overlay {
      transform: translateX(0%);
      display: flex;
      flex-direction: column; // Align items vertically
      justify-content: space-between; // Ensure items and button are spaced
    }

    .mobile-nav-item {
      margin-top: 84px;
      padding: 0 32px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around; // Ensures the menu items are spaced properly

      ul {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        li {
          position: relative;
          .menu-item {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: #2D3748;
            transition: color 0.3s ease-in-out;
            &:hover {
              color: #176939;
            }
            &.active {
              font-weight: 600;
              color: #176939;
            }
          }
        }
      }
    }

    .login-btn-mobile {
      width: 30%;
      margin: 32px auto; // Center the button horizontally and add margin for spacing
      align-self: center; // Ensure it stays centered
    }
  }
}

@include breakpoint(sm-desktop) {
  .main-nav {
    grid-template-columns: 2rem 1fr 8fr 1fr 2rem;
  }

}







@include breakpoint(tablet) {
  .main-nav {
    height: 48px;
    grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
    .logo {
      z-index: 2;
      grid-row: 1/2;
      grid-column: 2/3;
      img{
        height: 30px;
      }
    }
    .menu-link {
      display: none;
    }
    .last-column {
      grid-row: 1/2;
      grid-column: 4/5;
      .login-btn-desktop {
        display: none !important;
      }
      .mobile-menu {
        display: none; // Keep hidden initially
      }
      &.overlay .mobile-menu {
        display: flex; // Show mobile menu when overlay is active
      }

      .mobile-menu-button-wrapper {
        display: block;
        z-index: 2;
      }
    }
  }
}