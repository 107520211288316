@import "../../asset/scss/variable.scss";
@import "../../asset/scss/mixin.scss";

.product-range-section {
  padding-top: 120px;
  .product-range-container {
    max-width: 85%;
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
    .centered-container {
      width: fit-content;
      margin: 0 auto;
      color: $GreenG1;
      .title-style {
        position: relative;
        display: inline-block;
        font-size: 44px;
        font-weight: 275px;
        line-height: 66px;
        letter-spacing: -0.05em;
        text-align: center;
        z-index: 1;
        .middleTextBold {
          font-weight: 800;
        }
        .backgroundStyle {
          width: 401px;
          height: 55.53px;
          position: absolute;
          top: 5%;
          left: 5%;
          transform: translate(-50% -50%);
          background: #f8c325;
          z-index: -1;
          transform: skewY(-2deg);
        }
      }
    }
    .product-card-section {
      padding-top: 4rem;
      padding-bottom: 4rem;
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 1rem;
      .card {
        height: 358px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        .text-container {
          background-color: $white;
          opacity: 0.9;
          height: 78.32px;
          width: fit-content;
          padding: 0 2rem;
          font-size: 24px;
          font-weight: 500;
          line-height: 27.6px;
          color: $Black;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .card-1 {
        grid-column: 1 / 3;
      }
      .card-7 {
        grid-column: 2 / 4;
        display: flex;
        .text-container {
          align-self: flex-end;
        }
      }
      .card-4 {
        display: flex;
        .text-container {
          align-self: flex-end;
        }
      }
    }
    .product-text {
      font-size: 64px;
      font-weight: 600;
      line-height: 70.4px;
      color: $GreenG1;
      padding: 1rem 0;
      span {
        color: $BlackB3;
      }
    }
    .product-table {
      padding: 3rem 0;
      table {
        thead {
          height: 73.17px;
          background-color: $GreenG1;
          font-size: 29.27px;
          font-weight: 500;
          line-height: 43.9px;
          text-align: center;
          color: $Yellow;
        }
        tbody {
          tr {
            height: 73.17px;

            &:nth-child(odd) {
              background-color: #f8c32533;
            }
            &:nth-child(even) {
              background-color: #ccd9d066;
            }
            td {
              text-align: center;
              font-size: 25.61px;
              font-weight: 400;
              line-height: 38.41px;
              color: $GreenG1;
            }
          }
        }
      }
    }
    .product-card-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .card-btn {
        height: 73px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 2rem;
        border: 0.5px solid #cccccc;
        box-shadow: 0px 16px 120px 0px #161e2414;
        background-color: $white;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: $BlackB1;
        span {
          font-weight: 700;
        }
      }
    }
    .menu-card {
      padding: 3rem 0;
    }
    .button-section {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }
}

@include breakpoint (sm-desktop) {
  .product-range-section {
  padding-top: 100px;
    .product-range-container {
      .product-card-section {
        .card {
          height: 300px;
          .text-container {
            height: 65px;
            padding: 0 1rem;
          }
        }
      }
      .product-text {
        font-size: 54px;
      }
      .product-card-btn {
        .card-btn {
          height: 73px;
          padding: 0 0.5rem;
          font-size: 18px;
        }
      }
    }
  }
}


@include breakpoint (mobile) {
  .product-range-section {
    padding-top: 60px;
    .product-range-container {
      padding-left: 1rem;
      padding-right: 1rem;
      .centered-container {
        .title-style {
          font-size: 14.26px;
          line-height: 21.4px;
          .backgroundStyle {
            width: 129.45px;
            height: 18px;
          }
        }
      }
      .product-card-section {
        padding-top: 2rem;
        padding-bottom: 2rem;
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 0.5rem;
        .card {
          height: 108.37px;
          .text-container {
            background-color: $white;
            opacity: 0.9;
            height: 23.71px;
            width: fit-content;
            padding: 0 1rem;
            font-size: 7.15px;
            font-weight: 500;
            line-height: 8.23px;
          }
        }
      }
      .product-text {
        font-size: 19.08px;
        line-height: 20.99px;
        padding: 0.5rem 0;
      }
      .product-table {
        padding: 1.5rem 0;
        table {
          thead {
            height: 19.66px;
            font-size: 8.72px;
            line-height: 13.09px;
          }
          tbody {
            tr {
              height: 22.15px;
              td {
                text-align: center;
                font-size: 7.63px;
                line-height: 11.45px;
              }
            }
          }
        }
      }
      .product-card-btn {
        
        .card-btn {
          height: 22.1px;
          padding: 0 0.5rem;
          border: 0.15px solid #cccccc;
          
          background-color: $white;
          border-radius: 2.38px;
          font-size: 5.96px;
          line-height: 8.35px;
        }
      }
      .menu-card {
        padding: 1.5rem 0;
      }
      .button-section {
      display: none;
      }
    }
  }
  
}