@import "../../asset/scss/variable.scss";
@import "../../asset/scss/mixin.scss";

.LowRisk-section {
  // margin-bottom: 8.5rem;
  padding: 120px 0;
  .LowRisk-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .logo {
      width: 80%;
      img {
        width: 100%;
      }
    }
    .title {
      font-size: 40px;
      font-weight: 400;
      line-height: 60.2px;
      color: $BlackB1;
      span {
        font-weight: 600;
        color: $Yellow;
      }
    }
    .text {
      font-size: 18px;
      font-weight: 400;
      line-height: 27.09px;
      text-align: center;
      color: #544e47;
    }
    .button-section {
      padding-top: 3rem;
      display: flex;
      gap: 1rem;
    }
  }
}

@include breakpoint(mobile) {
  .LowRisk-section {
    // margin-bottom: 2.5rem;
    padding: 60px 0;
    .LowRisk-container {
      .logo {
        width: fit-content;
        img {
          width: 100%;
        }
      }
      .title {
        font-size: 14px;
        line-height: 21.07px;
      }
      .text {
        font-size: 10px;
        line-height: 15.05px;
      }
      .button-section {
        padding-top: 1rem;
        display: flex;
        gap: 0.5rem;
      }
    }
  }
}
