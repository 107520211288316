@import "../../asset/scss/mixin.scss";

.gallery-section {
    padding-top: 120px;
  .gallery-container {
    max-width: 85%;
    margin: 0 auto;
    .heading{
      .centered-container-1 {
        width: fit-content;
        margin: 0 auto;
        color: #176939;
        .title-style {
          position: relative;
          display: inline-block;
          font-size: 3.5rem;
          font-weight: 275;
          line-height: 4.5rem;
          text-align: center;          
          z-index: 1;
          .middleTextBold {
            font-weight: 600;
          }
          .backgroundStyle {
            width: 600px;
            height: 60px;
            position: absolute;
            left: 0%;
            transform: translate(-50% -50%);
            background: #f8c325;
            z-index: -1;
            transform: skewY(-2deg);
          }
        }
      }
      .centered-container-2 {
        width: fit-content;
        margin: 0 auto;
        color: #176939;
        .title-style {
          position: relative;
          display: inline-block;
          font-size: 3.5rem;
        font-weight: 275;
        line-height: 4.5rem;
          text-align: center; 
          z-index: 1;
          .middleTextBold{
            font-weight: 600;
          }
          .backgroundStyle {
            width: 600px;
            height: 60px;
            position: absolute;
            bottom: 0%;
            left: 0%;
            transform: translate(-50% -50%);
            background: #f8c325;
            z-index: -1;
            transform: skewY(-2deg);
          }
        }
      }
    }
    .image-container{
    padding-top: 64px;
        table{
            width: 100%;
            margin: 0 auto;
            border-spacing: 0.2rem;
            img{
              width: 100%;
            }
            
        }
        @media  screen and (max-width: 1444px) {
            table{
                border-spacing: 0.2rem;
            }   
        }
    }
  }
}


@include breakpoint(tablet){
  .gallery-section {
    padding-top: 80px;
  .gallery-container {
    .heading{
      .centered-container-1 {
        .title-style {
          font-size: 2rem;
          line-height: 2.5rem;
          .backgroundStyle {
            width: 400px;
            height: 40px;
          }
        }
      }
      .centered-container-2 {
        .title-style {
          font-size: 2rem;
          line-height: 2.5rem;
          .backgroundStyle {
            width: 350px;
            height: 40px;
          }
        }
      }
    }
    .image-container{
    padding-top: 48px;
    }
  }
}
}

@include breakpoint(mobile){
  .gallery-section {
    padding-top: 60px;
  .gallery-container {
    .heading{
      .centered-container-1 {
        .title-style {
          font-size: 1.5rem; 
          line-height: 2rem; 
          .backgroundStyle {
            width: 200px;
            height: 30px;
          }
        }
      }
      .centered-container-2 {
        margin-top: 20px;
        .title-style {
          font-size: 1.5rem; 
          line-height: 2rem;        
          .backgroundStyle {
            width: 200px;
            height: 30px;
          }
        }
      }
    }
    .image-container{
      padding-top: 32px;
    }
  }
}
}
