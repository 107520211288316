@import "../../asset/scss/mixin.scss";

.cloud-kitchen-section{
    position: relative;
    background: linear-gradient(4.16deg, rgba(58, 56, 56, 0.63) 6.93%, rgba(0, 0, 0, 0.63) 95.4%),
                url("../../asset/images/contact_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 80px);
    display: flex;
    .cloud-kitchen-container{
        max-width: 85%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .heading{
            font-size: 5.5rem;
            font-weight: 700;
            line-height: 6.25rem;
            text-align: center; 
            color: #FEF9E9;
            position: relative;
            span{
                position: relative;
                bottom: 10%;

            }
        }
        .text{
font-size: 2rem;
font-weight: 400;
line-height: 2.5rem;
color: #FEF9E9;
text-decoration: underline;
        }
    }
}




@include breakpoint(mobile){
    .cloud-kitchen-section{
        height: calc(50vh - 40px);
        .cloud-kitchen-container{
            max-width: 90%;
            .heading{
                font-size: 2.75rem;
                line-height: 3.125rem;
                span{
                    bottom: 5%;
                }
            }
            .text{
    font-size: 1rem;
    line-height: 1.25rem;
            }
        }
    }

}