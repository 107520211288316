@import "../../asset/scss/mixin.scss";

.who-can-take-franchise-section {
  padding-top: 120px;
  .who-can-take-franchise-container {
    max-width: 85%;
    margin: 0 auto;
    .centered-container {
      width: fit-content;
      color: #176939;
      margin: 0 auto;
      text-align: center;
      .title-style {
        position: relative;
        display: inline-block;
        font-size: 3.5rem; /* Large font for desktop */
        font-weight: 275;
        line-height: 4.5rem;
        text-align: center;
        z-index: 1;      
        .middleTextBold {
          font-weight: 600;
        }
        .backgroundStyle {
          width: 250px;
          height: 60px;
          position: absolute;
          top: 50%;
          right: -15%;
          // right: -35%;
          transform: translate(-50%, -50%) skewY(-2deg); /* Proper centering with transform */
          background: #f8c325;
          z-index: -1;
        }
      }
    }
    .content {
      padding-top: 64px;
      .content-item {
        text-align: center;
        // padding: 1rem;
        .franchise-img {
          width: 100%;
          height: auto;
        }
        .title {
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 2.25rem;
          letter-spacing: -0.02em;
          color: #176939;
          padding-top: 8px;
          text-align: left;
        }
        .text {
          font-size: 1.25rem;
          font-weight: 300;
          line-height: 1.875rem;
          color: #544E47;
          padding-top: 8px;
          text-align: left;
        }
      }
    }
  }
}

/* Responsive breakpoints for centered-container and title-style */
@include breakpoint(sm-desktop) {
  .who-can-take-franchise-section {
    padding-top: 100px;
.who-can-take-franchise-container{
  .centered-container {
    .title-style {
      font-size: 2.5rem;
      line-height: 3.5rem;
      .backgroundStyle {
        width: 180px;
        height: 50px;
      }
    }
  }
}
  
  }
}

@include breakpoint(tablet) {
  .who-can-take-franchise-section {
    padding-top: 80px;
  .who-can-take-franchise-container{
    .centered-container {
      .title-style {
        font-size: 2rem;
        line-height: 2.5rem;
        .backgroundStyle {
          width: 150px;
          height: 40px;
        }
      }
    }
  }
  }
}

@include breakpoint(mobile) {
  .who-can-take-franchise-section {
    padding-top: 60px;
    .who-can-take-franchise-container{
      .centered-container {
        .title-style {
          font-size: 1.5rem;
          line-height: 2rem;
          .backgroundStyle {
            width: 120px;
            height: 30px;
          }
        }
      }
      .content {
        padding-top: 32px;
        .content-item {
          .title {
            font-size: 0.875rem;
            line-height: 1rem;
          }
          .text {
            font-size: 0.75rem;
            line-height: 1.125rem;
            // text-align: center;
          }
        }
      }
    }
  }
}

