@import "../../asset/scss/mixin.scss";


.finantial-overview-section{
    background-color: #176939;
    padding: 120px 0;
    .finantial-overview-container{
        max-width: 85%;
        margin: 0 auto;
        .heading{
            font-size: 4.5rem;
            font-weight: 300;
            line-height: 5.5rem;
            color: #F9FCFF;
        }
        .item-container{
            padding-top: 40px;
display: grid;
grid-template-columns: auto auto;
gap: 40px;
align-items: center;
            .text{
font-size: 24px;
font-weight: 400;
line-height: 28.65px;
color: #F9EDDC;
text-align: justify;
            }
            .table{
                grid-column: 2/3;
                justify-self: center;
               table{
                border-spacing: 0;
                border-collapse: collapse;
                thead{
                    
                   tr{
                    th{
                        background-color: #F8C325;
font-size: 28px;
font-weight: 500;
line-height: 34.19px;
text-align: left;
color: #276229;
padding: 20px 40px;
border-bottom: 1px solid #2D3748;
&:first-child{
    border-radius: 10px 0 0 0;
}
&:last-child{
    border-radius: 0 10px 0 0;
}
                    }
                   }
                }
                tbody{
                    background-color: #F9EDDC;
                   tr{
                    td{
                        padding: 20px 40px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.3px;
    color: #2D3748;
                        border: 1px solid #2D3748;
                        
                       }
                       &:last-child{
                        td{
                            &:first-child{
                                border-radius: 0 0 0 10px;
                            }
                            &:last-child{
                                border-radius: 0 0 10px 0;
                            }
                        }
                       }
                   }
                }
               }
            }
        }
    }
}

@include breakpoint(mobile){
    .finantial-overview-section{
        padding: 60px 0;
        .finantial-overview-container{
            max-width: 85%;
            margin: 0 auto;
            .heading{
                font-size: 2.5rem;
                line-height: 3.5rem;
            }
            .item-container{
                padding-top: 20px;
    display: grid;
    grid-template-columns: none !important;
    grid-template-rows: auto auto;
    column-gap: 0px;
    align-items: center;
                .text{
                    grid-column: none;
                    grid-row: 1/2;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #F9EDDC;
    text-align: justify;
                }
                .table{
                    width: 100%;
                    grid-column: none;
                    grid-row: 2/3;
                    justify-self: center;
                   table{
                    border-spacing: 0;
                    border-collapse: collapse;
                    thead{
                        
                       tr{
                        th{
                            background-color: #F8C325;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #276229;
    // padding: 10px 0px;
    border-bottom: 1px solid #2D3748;
    &:first-child{
        border-radius: 5px 0 0 0;
    }
    &:last-child{
        border-radius: 0 5px 0 0;
    }
                        }
                       }
                    }
                    tbody{
                        background-color: #F9EDDC;
                       tr{
                        td{
                            // padding: 20px 0px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #2D3748;
        text-align: center;
                            border: 1px solid #2D3748;
                            
                           }
                           &:last-child{
                            td{
                                &:first-child{
                                    border-radius: 0 0 0 5px;
                                }
                                &:last-child{
                                    border-radius: 0 0 5px 0;
                                }
                            }
                           }
                       }
                    }
                   }
                }
            }
        }
    }
}