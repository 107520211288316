@import "../../asset/scss/mixin.scss";

.product-category-section{
    background-color: #003F15;
    padding: 120px 0;
    .product-category-container{
        max-width: 85%;
        margin: 0 auto;
        .heading{
            font-size: 4.5rem;
            font-weight: 300;
            line-height: 5.5rem;
color: #FFF;            
        }
        .text{
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2rem;
            color: #FFF;
            padding: 40px 0; 
        }
        .cards{
            display: flex;
            align-items: center;
            gap: 20px;
            span{
                padding: 10px 20px;
font-size: 1.25rem;
font-weight: 400;
line-height: 1.5rem;
text-align: center;
color: #2D3748;
border-radius: 10px;
                background: #F9EDDC;
                border: 1.04px solid #F8C324;
                box-shadow: 0px 0px 10.41px 0px #0000000D;

            }
        }
        .content-item{
            padding: 40px 0;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 32px;
            .card{
                background-color: #176939;
                border-radius: 10px;
                padding: 20px;
                img{
                    width: 100%;
                    height: auto;
                }
                .title{
font-size: 1rem;
font-weight: 300;
line-height: 1rem;
color: #FFF;
                }
            }
            .card-5,.card-6,.card-7,.card-8{
                background-color: #F8C325;
                .title{
                    color: #000;
                }
            }
        }
        .bottom-section{
            display: flex;
            flex-direction: column;
            align-items: center;
            .zomato-text{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
font-size: 1.5rem;
font-weight: 600;
line-height: 3rem;
color: #FFFFFF;
            }
            .zomato-link{
font-size: 24px;
font-weight: 600;
line-height: 36px;
text-decoration: underline;
color: #FFF;
            }
        }
    }
}

@include breakpoint(mobile) {
    .product-category-section{
        padding: 60px 0;
        .product-category-container{
            .heading{
                font-size: 2.5rem;
                font-weight: 300;
                line-height: 3.5rem;           
            }
            .text{
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5rem;
                color: #FFF;
                padding: 20px 0; 
            }
            .cards{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;
                span{
                    padding: 5px 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    border-radius: 5px;
                    background: #F9EDDC;
                    border: 1.04px solid #F8C324;
                    box-shadow: 0px 0px 10.41px 0px #0000000D;
    
                }
            }
            .content-item{
                padding: 20px 0;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 16px;
                .card{
                    background-color: #176939;
                    border-radius: 5px;
                    padding: 10px;
                    img{
                        width: 100%;
                        height: auto;
                    }
                    .title{
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1rem;
    color: #FFF;
                    }
                }
                
            }
            .bottom-section{
                display: flex;
                flex-direction: column;
                align-items: center;
                .zomato-text{
                    gap: 5px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: #FFFFFF;
                }
                .zomato-link{
    font-size: 16px;
    line-height: 24px;
                }
            }
        }
    }
}