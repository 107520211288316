@import "../../asset/scss/variable.scss";
@import "../../asset/scss/mixin.scss";


.TechnologyInfrastructure-section {
  margin-bottom: 12.5rem;
  .TechnologyInfrastructure-container {
    max-width: 85%;
    margin: 0 auto;
    .centered-container {
      width: fit-content;
      margin: 0 auto;
      color: $GreenG1;
      .title-style {
        position: relative;
        display: inline-block;
        font-size: 44px;
        font-weight: 275px;
        line-height: 66px;
        letter-spacing: -0.05em;
        text-align: center;
        z-index: 1;
        .prefixTextBold {
          font-weight: 800;
        }
        .backgroundStyle {
          width: 380px;
          height: 55.81px;
          position: absolute;
          top: 5%;
          left: 5%;
          transform: translate(-50% -50%);
          background: #f8c325;
          z-index: -1;
          transform: skewY(-2deg);
        }
      }
    }
    .technology-container {
      padding-top: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 100%;
      .technology-left_side {
        background-color: $Brown;
        width: 50%;
        height: 599px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 2rem;
        .left-top-section {
          .title {
            font-size: 44.1px;
            font-weight: 300;
            line-height: 59.33px;
            letter-spacing: -0.03em;
            text-align: center;
            color: $Yellow;
            span {
              font-style: italic;
              font-weight: 600;
            }
          }
          .text {
            font-size: 22.24px;
            font-weight: 400;
            line-height: 33.37px;
            letter-spacing: -0.03em;
            text-align: center;
            color: $white;
          }
        }
        .middle-section {
          img {
            width: 276px;
          }
        }
        .bottom-section {
          .content {
            display: flex;
            align-items: center;
            gap: 2rem;
            color: #EDEDED;
            .left {
              width: 57.2px;
              aspect-ratio: 1/1;
              object-fit: contain;
              border-radius: 12.71px;
              background-color: #8f782f;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .right {
              width: 100%;
              font-size: 19.07px;
              font-weight: 300;
              line-height: 28.6px;
              letter-spacing: -0.03em;
              text-align: left;
              span {
                font-weight: 700;
                font-style: italic;
              }
            }
          }
        }
      }
      .technology-right_side {
        background-image: url("../../asset/images/orderingApp-bg.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 50%;
        height: 599px;
        display: flex;
        justify-content: center;
        align-items: end;
        padding: 2rem;
        .ordering-app-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $white;
          width: 434px;
          height: 46px;
          border: 1.04px solid $Yellow;
          border-radius: 10.41px;
          font-size: 18.74px;
          font-weight: 500;
          line-height: 21.55px;
          color: $Black;
          box-shadow: 0px 0px 10.41px 0px #0000000d;
        }
      }
    }
  }
}

@include breakpoint (sm-desktop) {
  .TechnologyInfrastructure-section {
   
    .TechnologyInfrastructure-container {
      .technology-container {
        .technology-left_side {
          height: 530px;
          padding: 1rem;
          .left-top-section {
            .title {
              font-size: 40px;
            }
            .text {
              font-size: 20px;
            }
          }
          .middle-section {
            img {
              width: 250px;
            }
          }
          .bottom-section {
            .content {
              gap: 1rem;
              .right {
                font-size: 16px;
              }
            }
          }
        }
        .technology-right_side {
          height: 530px;
          padding: 1rem;
        }
      }
    }
  }
}

@include breakpoint (tablet) {
  .TechnologyInfrastructure-section {
    margin-bottom: 8rem;
    .TechnologyInfrastructure-container {
      .technology-container {
        .technology-left_side {
          .middle-section {
            img {
              width: 200px;
            }
          }
          .bottom-section {
            .content {
              .right {
                font-size: 12px;
                line-height: 20px;
              }
            }
          }
        }
        .technology-right_side {
          height: 530px;
          padding: 1rem;
        }
      }
    }
  }
}


@include breakpoint (mobile) {
  .TechnologyInfrastructure-section {
    margin-bottom: 4rem;
    .TechnologyInfrastructure-container {
      .centered-container {
        .title-style {
          font-size: 14.26px;
          line-height: 21.4px;
          .backgroundStyle {
            width: 129.45px;
            height: 18px;
          }
        }
      }
      .technology-container {
        padding-top: 2rem;
        gap: 0.1rem;
        .technology-left_side {
          background-color: $Brown;
          width: 163.5px;
          height: 158.05px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: 1rem;
          .left-top-section {
            .title {
              font-size: 11.64px;
              line-height:15.65px;
            }
            .text {
              font-size: 5.87px;
              line-height: 8.8px;
            }
          }
          .middle-section {
            img {
              width: 72.94px;
            }
          }
          .bottom-section {
            .content {
              gap: 0.5rem;
              .left {
                width: 15.09px;
                aspect-ratio: 1/1;
                border-radius: 3.35px;
                // padding: 0.5rem;
                img{
                  // object-fit: contain;
                  width: 70%;
                }
              }
              .right {
                width: 100%;
                font-size: 5.03px;
                line-height: 7.55px;
                
              }
            }
          }
        }
        .technology-right_side {
          background-image: url("../../asset/images/orderingApp-bg.png");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          height: 158.05px;
          width: 163.5px;
          display: flex;
          justify-content: center;
          align-items: end;
          padding: 1rem;
          .ordering-app-btn {
            width: 169.36px;
            height: 17.95px;
            border: 0.41px solid $Yellow;
            border-radius: 4.06px;
            font-size: 7.31px;
            line-height: 8.41px;
          }
        }
      }
    }
  }
}