.btn{
    border: none;
    outline: none;
font-size: 1rem;
font-weight: 500;
line-height: 1.5rem;
letter-spacing: 0.46000000834465027px;
height: 48px;

}
.primaryButton{
    text-decoration: none;
    color: #FFF;
    background-color: #276229;
    border-radius: 5px;
    border: 1px solid #276229 !important;
    &:hover{
        background-color: #3B9243 !important;
        border-color: #3B9243 !important;
        color: #FFF !important;
    }
}
.secondaryButton{
    height: 32px;
    color: #276229;
    border-radius: 5px;
    border: 1px solid #276229 !important;
    &:hover{
        background-color: #3B9243 !important;
        border-color: #3B9243 !important;
        color: #FFF !important;
    }
}
.secondSecondaryButton{
    color: #276229;
    border-radius: 5px;
    border: 1px solid #276229 !important;
    &:hover{
        background-color: #3B9243 !important;
        border-color: #3B9243 !important;
        color: #FFF !important;
    }
}

.secondPrimaryButton{
    width: 174px;
    height: 32px;
    text-decoration: none;
    color: #FFF;
    background-color: #276229;
    border-radius: 5px;
    border: 1px solid #276229 !important;
    &:hover{
        background-color: #3B9243 !important;
        border-color: #3B9243 !important;
        color: #FFF !important;
    }
}